import { post } from '../../until/axios_instans';

//获取按钮权限
export function researchSave(data){
    return post('/api/wenjuan/api/v1/wenjuan/researchSave',data);
}
// 上传文件

export function uploadFile(data) {
    return post('/api/fujian/api/v1/fujian/uploadFile', data);
}
    
export function _api_publish(data){
    return post('/api/wenjuan/api/v1/wenjuan/publish',data);
}
// 编辑问卷时获取调查问卷题目详情
export function _api_getLib(data){
    return post('/api/wenjuan/api/v1/wenjuan/editWj',data);
}
//编辑调研时获取问卷详情（来源：复制功能）


//获取题库列表二级
export function getQuestionTypeList(data){
    return post('/api/wenjuan/api/v1/platform/questionTypeList',data);
}

//获取题库列表预览数据
export function previewRemarkQuestion(data){
    return post('/api/wenjuan/api/v1/platform/previewRemarkQuestion',data);
}

//选择调研模板预览
export function getPreviewQuestionList(data){
    return post('/api/wenjuan/api/v1/platform/previewTemplate',data);
}
