import { post,get } from "../../until/axios_instans";
// 调研管理列表
export function customLibList(data){
    return post('api/wenjuan/api/v1/wenjuan/customLibList',data);
}
//历史调研列表
export function historyQuestion(data){
    return post('api/wenjuan/api/v1/wenjuan/historyQuestion',data);
}
// 调研管理结束
export function closeProject(data){
    return post('api/wenjuan/api/v1/wenjuan/closeProject',data);
}
// 调研管理发布
export function publish(data){
    return post('api/wenjuan/api/v1/wenjuan/publish',data);
}
// 调研管理预览
export function previewProject(data){
    return post('api/wenjuan/api/v1/wenjuan/previewProject',data);
}


//导出调研模板
export function exportTemplate(data){
    return get('api/wenjuan/api/v1/wenjuan/exportTemplate',data);
    // api/v1/wenjuan/exportTemplate
}

//下载调研模板
export function downResearch(data){
    return get('api/wenjuan/api/v1/wenjuan/downResearch',data);
}

//导入调研模板
export function uploadTemplate(data){
    return post('api/wenjuan/api/v1/wenjuan/uploadTemplate',data);
}

// 发布调研
export function publishQuestion(data){
    return post('api/wenjuan/api/v1/wenjuan/publishQuestion',data);
}
// 启动/暂停收集
export function changeCollection(data){
    return post('api/wenjuan/api/v1/wenjuan/changeCollection',data);
}
// 新建调研
export function createWj(data){
    return post('api/wenjuan/api/v1/wenjuan/createWj',data);
}
//删除调研
export function deleteProject(data){
    return post('api/wenjuan/api/v1/wenjuan/deleteProject',data);  
}

// 复制调研(不带标题)
export function copyQuestion(data){
    return post('api/wenjuan/api/v1/wenjuan/copyQuestion',data);  
}
//复制调研
export function copyProject(data){
    return post('api/wenjuan/api/v1/wenjuan/copyProject',data);  
}



// 无效答卷列表
export function InValidQuestionList(data){
    return post('api/wenjuan/api/v1/wenjuan/InValidQuestionList',data);
}
// 机构题库列表-无效

export function relatedContent(data){
    return post('api/wenjuan/api/v1/wenjuan/relatedContent',data);
}
// 单个或批量标记有效确认

export function batchValid(data){
    return post('api/wenjuan/api/v1/wenjuan/batchValid',data);
}
// 查看单个答卷有(无)效
export function validCheck(data){
    return post('api/wenjuan/api/v1/wenjuan/validCheck',data);
}
// 上下调研

export function lastNext(data){
    return post('api/wenjuan//api/v1/wenjuan/lastNext',data);
}

// 查看单个答卷有(无)效
export function answerHeader(data){
    return post('api/wenjuan/api/v1/wenjuan/answerHeader',data);
}
//选择调研模板列表
export function chooseTemplateList(data){
    return post('api/wenjuan/api/v1/platform/chooseTemplateList',data);
}

//编辑调研
export function editTemplate(data){
    return post('api/wenjuan/api/v1/platform/editTemplate',data);
}