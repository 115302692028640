import React, { useEffect, useState } from 'react'
import { Col, Form, Rate, Input, Row, Checkbox, Radio, message } from 'antd';
import { connect } from 'react-redux';
import { fetchPreviewQuestionList, _edit_PreviewQuestionList, getTemplateInfo } from '../../store/WEN_JUAN/previewQuestion/action'
import { copyQuestion } from '../../../src/api/Homeinterface/index'
import HeaderText from '../../components/headerText'
import Image from "../../../src/components/image/index"
import './index.less';
import { Button } from 'antd/lib/radio';

const { TextArea } = Input;

const mapStateToProps = ({ PREVIEWQUESTION }) => {
    const { title, introduction, questionList } = PREVIEWQUESTION;
    return {
        title, introduction, questionList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPreviewQuestionList(data) {
            dispatch(fetchPreviewQuestionList(data))
        },
        _edit_PreviewQuestionList(data) {
            dispatch(_edit_PreviewQuestionList(data))
        },
        getTemplateInfo(data) {
            dispatch(getTemplateInfo(data));
        }
    };
}

function _get_query(name) {

    const search = window.location.href

    if (!search) return null

    let item = search.split('?')[1];
    if (!item) return null;
    if (item.indexOf("&") !== -1) {
        item = item.split("&")
    } else {
        item = [item]
    }

    // const fi = item.find(v => v.includes(name)) ?.split('=')[1]
    const fi = item.find(v => v.includes(name)) ? item.find(v => v.includes(name)).split('=')[1] : ''
    if (item) {
        return fi
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}
let PreviewQuestion = props => {
    const { title, introduction, questionList, bgi } = props
    const { fetchPreviewQuestionList, _edit_PreviewQuestionList, getTemplateInfo } = props
    const [rateValue, setRateValue] = useState({})
    const [wjType, setWjtype] = useState(null)
    //判断左上角按钮的显示
    const type = _get_query("type") || ''
    const [isShow, setisShow] = useState(type ? true : false)


    useEffect(() => {
        setWjtype(_get_query('wjType'))
        // url中有libid说明从首页跳转过来，取id请求接口
        // 没有则说明是从编辑问卷页面预览跳转过来，直接取props里的数据

        if (_get_query('libid')) {
            fetchPreviewQuestionList({ libId: _get_query('libid') })
        } else if (_get_query('templateId')) {
            getTemplateInfo({ templateId: _get_query('templateId') });
        }
        else {
            const o = window.sessionStorage.getItem('_send_obj_preview') || {};
            const parserO = JSON.parse(o)
            _edit_PreviewQuestionList(parserO)

        }
        // 根据type值判断来源决定左上角按钮的出现
    }, [])
    // 题目标题dom
    const createQuestionTitle = (item, index) => {
        return (
            <div className="questionTitle" key={'title' + index}>
                <div>
                    {item.isMust === 1 && <span style={{ color: "#fc3f33" }}>*</span>}
                    <span>Q{index + 1}:{item.questionTitleList && item.questionTitleList.title}</span>
                </div>
                {item.questionTitleList && item.questionTitleList.imgList.length !== 0 && (
                    <div className="questionTitleImg">
                        {item.questionTitleList.imgList.map((imgItem, imgIndex) => (
                            <div key={'titleImg' + imgIndex} style={{ marginRight: '2%' }}>
                                <Image type='s' suffix={imgItem.mediaType} id={imgItem.imgId} />

                            </div>)
                        )}
                    </div>
                )}
            </div>
        )
    }

    // 修改评分
    const changeRate = (value, index) => {
        rateValue[index] = value
        setRateValue({ ...rateValue })
    }

    // 答题dom
    const createQuestion = data => {
        console.log(data);
        let dom = []
        // 题目序号是使用的index，段落说明也占一个index但没有序号，所以需要排除
        let graphCount = 0 // 段落说明出现次数，出现一次+1
        let graphIndexList = [] // 段落说明出现index的list
        let isBeforeGraph = true // 题目是否在第一个段落说明前
        if (data.length === 0) {
            return null
        }
        // 题型：0单选题，1多选题，2简答题，3五星评价题，4填空题，5段落说明
        data.forEach((item, index) => {
            switch (item.questionType) {
                case 0:
                    isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
                    dom.push(
                        <Form.Item key={item.questionId}>
                            <div>
                                {createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                                <div className="q_body">
                                    <Form.Item name={item.questionId} rules={[item.isMust === 1 ? { required: true, message: '此项必填!' } : {}]}>
                                        <Radio.Group>
                                            {item.optionList.length !== 0 && item.optionList.map((optionItem, optionIndex) => {
                                                let optionText = String.fromCharCode(65 + optionIndex) + "." + optionItem.optionTitle
                                                if (optionItem.imgList.length) {
                                                    return (
                                                        <Radio key={optionItem.optionId} value={optionItem.optionId}>
                                                            <div className="optionImg">
                                                                {optionText}
                                                                <Image type='s' suffix={optionItem.mediaType} id={optionItem.imgList[0].imgUrl} />

                                                            </div>
                                                        </Radio>
                                                    )
                                                }
                                                return (
                                                    <Radio key={optionItem.optionId} value={optionItem.optionId}>{optionText}</Radio>
                                                )
                                            })}
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form.Item>
                    )
                    break;
                case 1:
                    isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
                    let optionMax = item.optionMax
                    let optionMin = item.optionMin
                    dom.push(
                        <Form.Item key={item.questionId}>
                            <div>
                                {createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                                <div className="q_body">
                                    <Form.Item
                                        name={item.questionId}
                                    // rules={[item.isMust === 1 ? { type: 'array', max: optionMax, min: optionMin, required: true, message: '此项必填!' }
                                    //     :
                                    //     { type: 'array', max: optionMax, min: optionMin }]}
                                    >
                                        <Checkbox.Group>
                                            <Row>
                                                {item.optionList.length !== 0 && item.optionList.map((optionItem, optionIndex) => {
                                                    let optionText = String.fromCharCode(65 + optionIndex) + "." + optionItem.optionTitle
                                                    if (optionItem.imgList.length) {
                                                        return (
                                                            <Col key={optionItem.optionId} span={24}>
                                                                <Checkbox value={optionItem.optionId}>
                                                                    <div className="optionImg">
                                                                        {optionText}
                                                                        <Image type='s' suffix={optionItem.mediaType} id={optionItem.imgList[0].imgUrl} />
                                                                    </div>
                                                                </Checkbox>
                                                            </Col>
                                                        )
                                                    }
                                                    return (
                                                        <Col key={optionItem.optionId} span={24}>
                                                            <Checkbox value={optionItem.optionId}>{optionText}</Checkbox>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form.Item>
                    )
                    break;

                case 2:
                    isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
                    dom.push(
                        <Form.Item key={item.questionId}>
                            <div>
                                {createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                                <div className="q_body">
                                    <Form.Item name={item.questionId} rules={[item.isMust === 1 ? { required: true, message: '此项必填!' } : {}]}>
                                        <TextArea autoComplete="off" rows={4} showCount maxLength={100} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form.Item>
                    )
                    break;

                case 3:
                    const desc = item.optionList.length !== 0 && item.optionList.map(item => item.optionTitle);
                    desc.reverse()
                    let value = rateValue[index]
                    isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
                    dom.push(
                        <Form.Item key={item.questionId}>
                            <div>
                                {createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                                <div className="q_body" id="rateDiv">
                                    <Form.Item name={item.questionId} >
                                        <Rate onChange={(e) => changeRate(e, index)} allowClear={false} value={value} />
                                    </Form.Item>
                                    {value ? <span className="ant-rate-text">"{desc[value - 1]}"</span> : ''}
                                </div>
                            </div>
                        </Form.Item>
                    )
                    break;

                case 4:
                    isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
                    dom.push(
                        <Form.Item key={item.questionId}>
                            <div>
                                {createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                                <div className="q_body">
                                    <Form.Item name={item.questionId} rules={[item.isMust === 1 ? { required: true, message: '此项必填!' } : {}]}>
                                        <Input autoComplete="off" />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form.Item>
                    )
                    break;
                case 5:
                    graphCount += 1
                    graphIndexList.push(index)
                    dom.push(
                        <div className="questionParagraph" key={item.questionId}>
                            {/*{item.questionTitleList && item.questionTitleList.title}*/}
                            <div
                                dangerouslySetInnerHTML={{ __html: item.questionTitleList && item.questionTitleList.title }}
                            ></div>
                            <div>
                                {
                                    item.questionTitleList.imgList.map(v => {
                                        return <Image id={v.imgId} suffix={v.suffix} imgOption={{ width: '80px', height: '80px' }} type='s' />
                                    })
                                }
                            </div>
                        </div>
                    )
                    break;

                default:
                    break;
            }
        });
        return <div>
            <Form
                name="questionForm"
            >
                {dom}
            </Form>
        </div>
    }

    const handClick = () => {
        props.history.push('/editQuestion/EditContent')
        // console.log(fetchPreviewQuestionList,"圣诞节将");
    }
    const RightClick = async () => {
        const libId = _get_query("libid")
        const type = _get_query('type');
        if (type === '0') {
            let item = sessionStorage['router'] ? JSON.parse(sessionStorage['router']) : {};
            props.history.push(`/editQuestion/editContent?oldid=${item.result}&title=${item.questionTitle}&edit=${~~((Math.random() + 1) * 10)}&type=$0&wjType=${wjType}`)
        } else if (type === '1') {
            let res = await copyQuestion({ libId })
            if (res) {
                props.history.push(`/editQuestion/editContent?libid=${res.result.libId}&title=${res.result.questionTitle}&edit=${~~((Math.random() + 1) * 10)}&type=${type}&oldid=${libId}&wjType=${wjType}`)
            } else {
                this.mesWarning("数据错误")
            }
        } else if (type === '2') {
            props.history.push(`/editQuestion/editContent?templateId=${_get_query('templateId')}&wjType=${wjType}`);
        }
    }
    return (
        <div className="previewQuestion">
            <div style={{ zIndex: 2 }}>
                <HeaderText handClick={() => handClick()} isnone={true} isShow={isShow} RightClick={() => RightClick()} RightText={type === "1" ? "复制调研" : "使用模板"} CenterText={'调研预览'} ></HeaderText>
            </div>
            <div className="topError">此页面为预览页面，不能参与作答！</div>
            <div className="questionHeader">
                {bgi ? <div className="bgi"></div> : ''}
                <div className="title">
                    {title}
                </div>
                <div className="introduction">
                    {introduction}
                </div>
                <div className="questionBody">
                    <div className="body_left">
                        {createQuestion(questionList)}
                    </div>
                </div>
            </div>

        </div>
    )
}

PreviewQuestion = connect(mapStateToProps, mapDispatchToProps)(PreviewQuestion)
export default PreviewQuestion
