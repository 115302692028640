import React, { Component, useState } from 'react';
import "./index.less"
import HeaderText from '../../components/headerText'
import { Select, Button, Input, Pagination, Menu, Dropdown, Modal, Popconfirm, message, Breadcrumb } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { closeProject, publish, previewProject, publishQuestion, changeCollection, chooseTemplateList, editQuestion } from '../../api/Homeinterface/index'
import moment from 'moment'
const GanTanHao = () => {

    return <div style={{ marginRight: 10 }}>
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 11.5L0 11.5C9.59018e-07 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5L0 11.5Z" fill="#F3B714" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6502 6.32502V13.225C12.6502 13.8602 12.1353 14.375 11.5002 14.375C10.865 14.375 10.3502 13.8602 10.3502 13.225V6.32502C10.3502 5.68989 10.865 5.17502 11.5002 5.17502C12.1353 5.17502 12.6502 5.68989 12.6502 6.32502ZM11.5001 17.825C12.1352 17.825 12.6501 17.3102 12.6501 16.675C12.6501 16.0399 12.1352 15.525 11.5001 15.525C10.865 15.525 10.3501 16.0399 10.3501 16.675C10.3501 17.3102 10.865 17.825 11.5001 17.825Z" fill="white" />
        </svg>
    </div>

}
function _get_query(name) {
    const search = window.location.href
    if (!search) return null
    const item = search.split('&').find(v => {
        return v.includes(name)
    });
    if (item) {
        const _item_arr = item.split('=')
        return _item_arr[1]
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}

const { Option } = Select;

//调研类型 
const arrData = [{
    text: '全部调研', id: ''
}, {
    text: '问卷', id: '0'
}, {
    text: '反馈', id: '1'
},
{
    text: '投票', id: '2'
}]

class index extends Component {
    state = {
        curbuttonid: '',
        // 下拉菜单
        visible: false,
        // 发布弹框
        virelease: false,
        // 结束弹框
        endvisible: false,
        // 列表
        dataList: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
        // 搜索名称
        Inputbox: '',

        //当前的 _libId
        _libId: _get_query('id')
    }
    // 发布弹框
    showModal = () => {
        this.setState({
            virelease: true,
        });

    };

    hideModal = (libId) => {
        const { _libId } = this.state
        publishQuestion({ libId: _libId }).then((res) => {
            console.log(res)
            this.setState(() => {
                return {
                    libId: _libId
                }
            })

        })

    };

    confirm = (e) => {
        console.log(e);
        message.success('Click on Yes');
    }

    cancel = (e) => {
        console.log(e);
        message.error('Click on No');
    }





    handleMenuClick = (e, v) => {
        this.setState(() => {
            return {
                visible: false
            }
        })
        if (e.key === '1') {
            this._preview(v)
        }
    };
    handleVisibleChange = flag => {


        this.setState(() => {
            return { visible: flag }
        });
    };

    onBlur = () => {
        console.log('blur');
    }
    onFocus = () => {
        console.log('focus');
    }
    onSearch = (val) => {
        console.log('search:', val);
    }
    // 状态
    onChangestatus = (item) => {
        this.getDataList({
            status: item,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
    }


    handClick = () => {
        this.props.history.push('/')
    }
    //新建调研
    build = () => {
        this.props.history.push('/create')
    }

    componentDidMount() {
        this.getDataList({
            pageSize: this.state.pageSize,
            pageNum: this.state.pageNum
        })
    }


    //  列表
    getDataList = (par) => {
        return chooseTemplateList(par).then(res => {

            if (res.code === 0) {

                this.setState(() => {
                    return {
                        dataList: res.result.data,
                        total: res.result.total
                    }
                })

            } else {
                this.mesWarning("数据错误")
            }


        })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };
    //输入框
    onInputbox = () => {
        const { Inputbox } = this.state;
        this.getDataList({
            questionTitle: Inputbox,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
    }
    // 类型
    getselect = (item) => {
        this.setState(() => {
            return {
                curbuttonid: item.id,
            }
        })
        this.getDataList({
            type: item.id,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
    }
    _preview = (v) => {
        this.props.history.push(`/previewQuestion?libid=${v.libId}&type=0`)
    }

    render() {
        const { dataList, total } = this.state
        return (
            <div className="main_box">
                <HeaderText handClick={() => this.handClick()} CenterText={'调研模板'}></HeaderText>
                {/* mian */}
                <div>
                    <Breadcrumb className="title_bread">
                        <Breadcrumb.Item>
                            <a href="">学习工具</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="">调研工具</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="">调研模板</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="main_create" style={{ marginTop: "20px" }}>
                    {/* main_top */}
                    <div className="m_buttom">
                        <div>

                            <div id="c_buttom_line"></div>
                            {

                                arrData.map((item, index) => {
                                    return <span key={index} onClick={() => {
                                        this.getselect(item)
                                    }}
                                        className={this.state.curbuttonid === item.id ? 'blue_btn' : ""} >{item.text}</span>
                                })
                            }

                        </div>
                    </div>
                    <div className="ipt">
                        <div>
                            <div id="c_input">
                                <Input
                                    className="in"
                                    prefix={<img src={window.$$setImgSrc('QN_searchIcon.png')} />}
                                    placeholder="请输入调研名称"
                                    style={{ width: 360 }}
                                    value={this.state.Inputbox}
                                    onChange={value => {
                                        this.setState({
                                            Inputbox: value.target.value
                                        })
                                    }}
                                    onPressEnter={v => {
                                        this.onInputbox()
                                    }}
                                />
                                <span className="c_span">共计{total}个调研项目</span>
                            </div>
                        </div>
                    </div>
                    <div className="main_border">
                        <div className="h_border">
                            {
                                dataList.map((v, i) => {

                                    return <div className='h_item' key={i}>
                                        <div>
                                            {
                                                v.type === '0' ? <img src={window.$$setImgSrc('QN_iconBlue.png')}></img> :
                                                    v.type === '1' ? <img src={window.$$setImgSrc('QN_iconOrigin.png')}></img> :
                                                        v.type === '2' ? <img src={window.$$setImgSrc('QN_iconGreen.png')}></img> : ''
                                            }
                                            <span
                                            >{v.type === '0' ? "问卷" : v.type === '1' ? "反馈" : v.type === '2' ? "投票" : ''}</span>
                                        </div>

                                        <div>
                                            <div>
                                                <div>
                                                    <h3>{v.questionTitle ? v.questionTitle.length > 15 ? `${v.questionTitle.substr(0, 15)}...` : v.questionTitle : ""}</h3>
                                                    <div
                                                        className="backgrounf_image"

                                                        style={{
                                                            backgroundColor: v.status === 0
                                                                ?
                                                                "rgba(231,1,1,0.1)" : v.status === 1
                                                                    ?
                                                                    "rgba(66,207,157,0.1)" : v.status === 2
                                                                        ?
                                                                        "rgba(231,83,1,0.1)" : v.status === 3
                                                                            ?
                                                                            "rgba(136,136,136,0.1)" : ''
                                                        }}
                                                    >

                                                        <span
                                                            className="background_lei"
                                                            style={{
                                                                color: v.status === 0
                                                                    ?
                                                                    "#F22E2E" : v.status === 1
                                                                        ?
                                                                        "#42CF9D" : v.status === 2
                                                                            ?
                                                                            "#E75301" : v.status === 3
                                                                                ?
                                                                                "#888888" : ''
                                                            }}
                                                        >
                                                            {v.status === 0 ?
                                                                "未发布" : v.status === 1 ?
                                                                    "采集中" : v.status === 2 ?
                                                                        "暂停中" : v.status === 3 ?
                                                                            "已结束" : ''}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            {/*  */}
                                            <div>
                                                {/* 问卷导语： */}
                                                {v.introduction ? v.introduction.length > 60 ? `${v.introduction.substr(0, 60)}...` : v.introduction : <>暂无导语 <br /></>}
                                            </div>
                                        </div>
                                        {/*  */}
                                        <div className="bottom_foo">
                                            <div className="pp" >
                                                <span>{v.questionNum || 0}道问题</span> <span>|</span>
                                                <span>模板被使用了{v.isUseNum || 0}次</span>
                                            </div>
                                            <div className="c_fonts">
                                                <span className="template_foo" onClick={() => {
                                                    sessionStorage.setItem('router', JSON.stringify(
                                                        { "result": v.templateId, "questionTitle": v.questionTitle }
                                                    ))
                                                    window.open(`/editQuestion/editContent?&type=2&templateId=${v.templateId}&wjType=${v.type}`)

                                                }}>
                                                    <em>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.61165 5.50164H2.38728C1.62178 5.50164 1 6.19684 1 7.05107V13.9489C1 14.8032 1.62178 15.5 2.38728 15.5H10.6127C11.3767 15.5 12 14.8048 12 13.9489V7.05107C12 6.19684 11.3782 5.5 10.6127 5.5H6.49927C6.30129 5.5 6.14145 5.68036 6.14145 5.90007C6.14145 6.12141 6.30276 6.30013 6.49927 6.30013H10.3869C10.8796 6.30013 11.2814 6.74939 11.2814 7.3003V13.6948C11.2814 14.2457 10.8796 14.695 10.3869 14.695H2.61165C2.11892 14.695 1.7171 14.2457 1.7171 13.6948V7.3003C1.7171 6.74939 2.11892 6.30013 2.61165 6.30013C2.68498 6.30013 2.7451 6.23291 2.7451 6.15093V5.64757C2.7451 5.56722 2.68498 5.50164 2.61165 5.50164Z" fill="#888888" stroke="#888888" strokeWidth="0.5" />
                                                            <path d="M13.6127 10.4984C14.3767 10.4984 15 9.80328 15 8.94754V2.05082C15 1.19672 14.3782 0.5 13.6127 0.5H5.38728C4.62325 0.5 4 1.19508 4 2.05082V8.94754C4 9.80164 4.62178 10.4984 5.38728 10.4984H9.50073C9.69871 10.4984 9.85855 10.318 9.85855 10.0984C9.85855 9.87705 9.69724 9.69836 9.50073 9.69836H5.61165C5.11892 9.69836 4.7171 9.24918 4.7171 8.69836V2.30492C4.7171 1.7541 5.11892 1.30492 5.61165 1.30492H13.3883C13.8811 1.30492 14.2829 1.7541 14.2829 2.30492V8.69836C14.2829 9.24918 13.8811 9.69836 13.3883 9.69836C13.315 9.69836 13.2549 9.76557 13.2549 9.84754V10.3508C13.2549 10.4328 13.315 10.5 13.3883 10.5H13.6127V10.4984Z" fill="#888888" stroke="#888888" strokeWidth="0.5" />
                                                        </svg>
                                                    </em>
                                                    <span style={{ margin: "0 2px" }}>使用模板</span>
                                                </span>
                                                <span onClick={() => {
                                                    sessionStorage.setItem('router', JSON.stringify(
                                                        { "result": v.libId, "questionTitle": v.questionTitle }
                                                    ))
                                                    window.open(`/previewQuestion?templateId=${v.templateId}&type=2&wjType=${v.type}`)
                                                }} className="template_foot" >
                                                    <em>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.00536 8.50346C8.27851 8.50346 8.50346 8.27851 8.50346 8.00535C8.50346 7.7322 8.27851 7.50725 8.00536 7.50725C7.7322 7.50725 7.50725 7.7322 7.50725 8.00535C7.50725 8.27851 7.72328 8.50346 8.00536 8.50346ZM8.00536 9.78353C7.36621 9.78353 6.78241 9.44432 6.46284 8.89087C6.14327 8.33564 6.14327 7.65901 6.46284 7.10377C6.78241 6.54854 7.37514 6.21111 8.00536 6.21111C8.99264 6.21111 9.79246 7.01093 9.79246 7.99821C9.79246 8.98549 8.99264 9.78353 8.00536 9.78353ZM1.25686 14.5717L1.29078 12.1616C1.29078 11.8045 0.999777 11.5224 0.65164 11.5224C0.294577 11.5224 0.0124972 11.8134 0.0124972 12.1616V14.927C0.0124972 15.5197 0.492747 16 1.08547 16H3.85093C4.20799 16 4.49007 15.709 4.49007 15.3609C4.49007 15.0127 4.19906 14.7217 3.85093 14.7217L1.44968 14.7467C1.34791 14.7485 1.264 14.6717 1.25686 14.5717ZM1.41576 1.29078H3.83843C4.19549 1.29078 4.47757 0.999777 4.47757 0.65164C4.47757 0.294577 4.18657 0.0124973 3.83843 0.0124973H1.07297C0.48025 0.0124973 0 0.492747 0 1.08547V3.85093C0 4.20799 0.291006 4.49007 0.639143 4.49007C0.996206 4.49007 1.27829 4.19906 1.27829 3.85093V1.42647C1.28007 1.35148 1.34077 1.29078 1.41576 1.29078ZM14.711 1.38898V3.83843C14.711 4.21513 15.0341 4.50792 15.4055 4.47579C15.7376 4.44722 15.9893 4.16157 15.9893 3.82772V1.07297C15.9893 0.48025 15.509 0 14.9163 0H12.1526C11.7956 0 11.5135 0.291007 11.5135 0.639143C11.5135 0.996206 11.8045 1.27829 12.1526 1.27829H14.6021C14.6628 1.28007 14.711 1.33006 14.711 1.38898ZM14.5378 14.7128H12.1634C11.7867 14.7128 11.4939 15.0359 11.526 15.4073C11.5546 15.7393 11.8402 15.9911 12.1741 15.9911H14.927C15.5197 15.9911 16 15.5108 16 14.9181V12.1544C16 11.7777 15.6769 11.4849 15.3055 11.5171C14.9734 11.5456 14.7217 11.8313 14.7217 12.1651V14.5325C14.7217 14.6324 14.6414 14.7128 14.5414 14.7128H14.5378Z" fill="#888888" />
                                                        </svg>
                                                    </em>
                                                    <span style={{ margin: "0 2px" }}>预览</span>
                                                </span>
                                            </div>
                                        </div>


                                    </div>
                                })
                            }
                            <div style={{
                                display: 'flex', justifyContent: "flex-end", width: "100%"
                            }}>
                                <Pagination
                                    dataSource={this.state.dataList} defaultCurrent={1}
                                    pageSizeOptions={['10', '20', '30']}
                                    onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)}
                                    onChange={(a, b) => this.onPageNumChange(a, b)}
                                    showSizeChanger={true}
                                    current={this.state.pageNum}
                                    pageSize={this.state.pageSize}
                                    total={this.state.total}
                                />
                            </div>
                        </div>
                    </div>

                </div>


            </div >
        );
    }
}

export default index;




















