import React, { Component } from 'react';
import './index.less';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from 'antd';

//获取base64编码格式的图片
function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / 333;
    const scaleY = image.naturalHeight / 333;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
    );
    // As Base64 string
    const base64Image = canvas.toDataURL('image/png');
    return base64Image;
}

//将base64转换为文件
function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}


export default class index extends Component {
    state = {
        crop: {
            unit: 'px', // default, can be 'px' or '%'
            x: 0,
            y: 83,
            width: 333,
            height: 160
        },
        cropingSrc: ''
    }
    //move mask change
    move = crop => {
        this.setState({ crop });
    }
    //保存
    back = () => {
        const { saveBack, fileName } = this.props;
        const { cropingSrc } = this.state;
        let file = dataURLtoFile(cropingSrc, fileName);
        saveBack(file);
    }
    onComplete = crop => {
        let img = new Image();
        img.src = this.props.src;
        img.crossOrigin = 'Anonymous';
        img.onload = async () => {
            const croppedImg = await getCroppedImg(img, crop);
            this.setState({ cropingSrc: croppedImg });
        }
    }
    render() {
        let { src, cancelBack, options } = this.props;
        let { crop, cropingSrc } = this.state;
        const props = {
            ...options,
            className: 'my_img_crop',
            onChange: this.move,
            onComplete: this.onComplete,
            crop,
            src
        }
        return (
            <div>
                <div className='crop_box'>
                    <div className='crop_img'>
                        <div className="top">
                            <ReactCrop {...props} />
                        </div>
                    </div>
                    <div className='preview'>
                        <p>预览效果</p>
                        <div className='image_container'>
                            <div>
                                <img className='small' src={cropingSrc} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <Button className='sure'  onClick={() => this.back()} type="primary">确定</Button>
                    <Button onClick={cancelBack} className='cancel'>取消</Button>
                </div>
            </div>
        )
    }
}
