import React from 'react';
import { Statistic, Modal, Progress } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';


const { Countdown } = Statistic;
const { confirm } = Modal;


class CountDown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            countPercent: 100,
            countFlag: false, // 是否开始倒计时
            isCountDown: false, // 是否有倒计时
        }
        this.countTime = 1000 // 正计时
    }

    componentDidMount() {
        let { countPercent } = this.state
        let { countDown } = this.props
        let isCountDown = countDown !== 0
        this.setState({
            isCountDown
        })
        if (isCountDown) {
            // 页面初始时展示confirm 确认后开始倒计时
            this.countTime = countDown
            let self = this
            isCountDown && confirm({
                title: '温馨提示',
                icon: <ExclamationCircleOutlined />,
                content: '本调研为计时调研，点击确定后，请在规定时间内完成作答',
                onOk() {
                    self.setState({
                        countFlag: true
                    }, () => {
                        self.countInterval = setInterval(() => {
                            countPercent = countPercent - 1
                            self.setState({
                                countPercent
                            })
                        }, countDown / 100) // countdown/100
                        self.timer = setInterval(() => {
                            this.countTime -= 1000
                        }, 1000)
                    })
                },
                onCancel() {
                    // back
                },
            });
        } else {
            this.countInterval = setInterval(() => {
                this.countTime += 1000
                this.setState({
                })

            }, 1000)
        }
    }

    componentWillUnmount() {
        clearInterval(this.countInterval)
        clearInterval(this.timer)
    }


    // 毫秒格式化时分秒
    secondToTime = m => {
        var time = parseFloat(m) / 1000;
        if (null != time && "" != time) {
            if (time > 60 && time < 60 * 60) {
                var a = parseInt(time / 60.0) < 10 ? ('0' + parseInt(time / 60.0)) : parseInt(time / 60.0);
                var ms = parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60);
                var b = (ms < 10) ? ('0' + ms) : ms;
                time = '00:' + a + ':' + b;
            }
            else if (time >= 60 * 60 && time < 60 * 60 * 24) {
                var H = parseInt(time / 3600.0) < 10 ? ('0' + parseInt(time / 3600.0)) : parseInt(time / 3600.0);
                var ms = parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60);
                var M = (ms < 10) ? ('0' + ms) : ms;
                var ss = parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
                    parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60);
                var S = (ss < 10) ? ('0' + ss) : ss;
                time = H + ':' + M + ':' + S;
            }
            else {
                var d = parseInt(time) < 10 ? ('0' + parseInt(time)) : parseInt(time);
                time = '00:00:' + d;
            }
        }
        return time
    }


    render() {
        const { countPercent, countFlag, isCountDown } = this.state
        return (
            <div>
                {isCountDown ? <Progress
                    type="circle"
                    width={180}
                    strokeColor={{
                        '0%': '#3E78ED',
                        '100%': '#1CBCF0',
                    }}
                    format={() => countFlag ? <Countdown value={Date.now() + 1000 * 600 * countPercent / 100} onFinish={this.countFinish} /> : <div style={{ color: '#000', fontSize: '24px' }}>{this.secondToTime(1000 * 600)}</div>}
                    percent={countPercent}
                /> :
                    <Progress
                        type="circle"
                        width={180}
                        strokeColor={{
                            '0%': '#3E78ED',
                            '100%': '#1CBCF0',
                        }}
                        format={() => <div style={{ color: '#000', fontSize: '24px' }}>{this.secondToTime(this.countTime)}</div>}
                        percent={100}
                    />}
            </div>
        )
    }
}

export default CountDown