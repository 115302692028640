import React from 'react'
import { Col, Form, Rate, message, Row, Checkbox, Button, Radio, Modal, Input } from 'antd';
import moment from 'moment';
import { getSubmitQuestion, getAnswerQuestionList } from '../../api/answerQuestion/index';
import HeaderText from '../../components/headerText'
import Image from "../../../src/components/image/index"
import AnswerCountDown from "../../components/answerCountDown";
import MaxLengthInput from '../../components/maxLengthInput'
import './index.less';

const { TextArea } = Input

const _id_create = () => {
  return 'xxxxxx-xxx-4xxx-yxx-xxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function _get_query() {
  let o = window.location.href.split("?")[1].split("&")
  // let s=o.map(v=>{
  //   let oo={
  //     [v.split("=")[0]]:v.split("=")[1]
  //   }
  //   return oo
  // })
  let obj = {}
  let res = o.map(v => {
    obj[v.split("=")[0]] = v.split("=")[1]
  })
  return obj;
}

class AnswerQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cardList: [], // 答题卡id list
      answerCardList: [], // 已答题目id list
      countPercent: 100,
      countFlag: false, // 是否开始倒计时
      rateValue: {},
      isCountDown: false, // 是否有倒计时
      status: 0, // 答卷状态 0未发布，1采集中，2暂停中，3已结束，为3的时候跳转到提示页
      beginTime: '', // 开始作答时间
      libId: _get_query().libid || '',
      modId: _get_query('modId').modId || '',
      sysFlag: _get_query('sysFlag').sysFlag || '',
      graphIndexList: [], // 段落说明出现index的list
      title: '',
      introduction: '',
      questionList: [],
      countDown: 0,
      activeIndex: null //答题中
    }

    this.questionForm = React.createRef()
    this.countDownRef = React.createRef()
    this.countTime = 1000 // 正计时
  }

  componentDidMount() {
    this.initData()
    if (!localStorage.getItem("__________cccccckkkkkkkkkkk_______")) {
      localStorage.setItem("__________cccccckkkkkkkkkkk_______", _id_create())
    }

  }

  initData = () => {
    let { libId } = this.state;

    getAnswerQuestionList({ libId }).then(res => {
      if (res && res.message === 'success') {
        const { result } = res
        // 答题卡list，剔除段落说明
        let cardList = []
        let graphIndexList = []
        result.questionList.length !== 0 && result.questionList.forEach((item, index) => {
          if (item.questionType !== 5) {
            cardList.push(item.questionId)
          } else {
            graphIndexList.push(index)
          }
        })
        this.setState(() => {
          return {
            title: result.questionTitle,
            introduction: result.introduction,
            questionList: [...result.questionList],
            countDown: result.countDown || 0,
            beginTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            cardList,
            graphIndexList,
            status: result.status || 0
          }
        })
        if (this.state.status === 3) {
          this.stopResearch()
        }
      } else {
        res && this.mesWarning(res.message)
      }
    })
  }

  // 题目标题dom
  createQuestionTitle = (item, index) => {
    // const { emptyList } = this.state
    return (
      <div className="questionTitle" key={'title' + index}>
        <div>
          {item.isMust === 1 && <span style={{ color: "#fc3f33" }}>*</span>}
          <span>Q{index + 1}:{item.questionTitleList && item.questionTitleList.title}</span>
          {/* {emptyList.includes(index) && <span style={{ color: '#fc3f33', fontSize: '12px' }}>此项必填</span>} */}
        </div>

        {/* // 旧版 */}
        {item.questionTitleList && item.questionTitleList.imgList.length !== 0 && (
          <div className="questionTitleImg">
            {item.questionTitleList.imgList.map((imgItem, imgIndex) => (
              <div key={'titleImg' + imgIndex} style={{ marginRight: '2%' }}>
                <Image type='s' suffix={imgItem.mediaType} id={imgItem.imgId} />
              </div>)
            )}
          </div>
        )}
        {/* {item.questionTitleList && item.questionTitleList.fjList.length !== 0 && (
          <div className="questionTitleImg">
            {item.questionTitleList.fjList.map((imgItem, imgIndex) => (
              <div key={'titleImg' + imgIndex} style={{ marginRight: '2%' }}>
                <img src={imgItem.fileName} alt="" />
              </div>)
            )}
          </div>
        )} */}
      </div>
    )
  }

  // 答题dom
  createQuestion = data => {
    let dom = []
    // 题目序号是使用的index，段落说明也占一个index但没有序号，所以需要排除
    const { graphIndexList } = this.state
    let graphCount = 0
    let isBeforeGraph = true // 题目是否在第一个段落说明前
    if (data.length === 0) {
      return null
    }
    const validateMessages = {
      required: '本题必答！',
      array: {
        max: "本题最多可选择${max}项！",
        range: "本题最多可选择${max}项！",
      }
    }
    // 题型：0单选题，1多选题，2简答题，3五星评价题，4填空题，5段落说明
    data.forEach((item, index) => {
      switch (item.questionType) {
        case 0:
          isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
          dom.push(
            <Form.Item key={item.questionId}>
              <div>
                {this.createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                <div className="q_body">
                  <Form.Item name={item.questionId} rules={[item.isMust === 1 ? { required: true } : {}]}  >
                    <Radio.Group onChange={() => { this.setState({ activeIndex: index - graphCount }) }}>

                      {/* // 旧版 */}
                      {item.optionList.length !== 0 && item.optionList.map((optionItem, optionIndex) => {
                        let optionText = String.fromCharCode(65 + optionIndex) + "." + optionItem.optionTitle
                        if (optionItem.imgList.length) {
                          return (
                            <Radio key={optionItem.optionId} value={optionItem.optionId}>
                              <div className="optionImg">
                                {optionText}
                                <Image type='s' suffix={optionItem.mediaType} id={optionItem.imgList[0].imgUrl} />
                              </div>
                            </Radio>
                          )
                        }
                        return (
                          <Radio key={optionItem.optionId} value={optionItem.optionId}>{optionText}</Radio>
                        )
                      })}
                      {/* {item.optionList.length !== 0 && item.optionList.map((optionItem, optionIndex) => {

                        let optionText = String.fromCharCode(65 + optionIndex) + "." + optionItem.optionTitle
                        if (optionItem.fjList.length) {
                          return (
                            <Radio key={optionItem.optionId} value={optionItem.optionId}>
                              <div className="optionImg">
                                {optionText}
                                <img src={optionItem.fjList[0].fileName} />
                              </div>
                            </Radio>
                          )
                        }
                        return (
                          <Radio key={optionItem.optionId} value={optionItem.optionId}>{optionText}</Radio>
                        )
                      })} */}
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
            </Form.Item>
          )
          break;
        case 1:
          isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
          let optionMax = item.optionMax
          let optionMin = item.optionMin
          dom.push(
            <Form.Item key={item.questionId}>
              <div>
                {this.createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                <div className="q_body">
                  <Form.Item
                    name={item.questionId}
                    rules={[item.isMust === 1 ? { type: 'array', max: optionMax, min: optionMin, required: true }
                      :
                      { type: 'array', max: optionMax, min: optionMin }]}
                  >
                    <Checkbox.Group onChange={() => { this.setState({ activeIndex: index - graphCount }) }}>
                      <Row>

                        {/* // 旧版 */}
                        {item.optionList.length !== 0 && item.optionList.map((optionItem, optionIndex) => {
                          let optionText = String.fromCharCode(65 + optionIndex) + "." + optionItem.optionTitle
                          if (optionItem.imgList.length) {
                            return (
                              <Col key={optionItem.optionId} span={24}>
                                <Checkbox value={optionItem.optionId}>
                                  <div className="optionImg">
                                    {optionText}
                                    <Image type='s' suffix={optionItem.mediaType} id={optionItem.imgList[0].imgUrl} />
                                  </div>
                                </Checkbox>
                              </Col>
                            )
                          }
                          return (
                            <Col key={optionItem.optionId} span={24}>
                              <Checkbox value={optionItem.optionId}>{optionText}</Checkbox>
                            </Col>
                          )
                        })}
                        {/* {item.optionList.length !== 0 && item.optionList.map((optionItem, optionIndex) => {
                          let optionText = String.fromCharCode(65 + optionIndex) + "." + optionItem.optionTitle
                          if (optionItem.fjList.length) {
                            return (
                              <Col key={optionItem.optionId} span={24}>
                                <Checkbox value={optionItem.optionId}>
                                  <div className="optionImg">
                                    {optionText}
                                    <img src={optionItem.fjList[0].fileName} />
                                  </div>
                                </Checkbox>
                              </Col>
                            )
                          }
                          return (
                            <Col key={optionItem.optionId} span={24}>
                              <Checkbox value={optionItem.optionId}>{optionText}</Checkbox>
                            </Col>
                          )
                        })} */}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </div>
            </Form.Item>
          )
          break;

        case 2:
          isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
          dom.push(
            <Form.Item key={item.questionId} name={item.questionId} rules={[item.isMust === 1 ? { required: true } : {}]}>
              <div>
                {this.createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                <div className="q_body">
                  {/* <MaxLengthInput
                    type="textarea"
                    maxLength={100}
                    formOptions={{
                      name: item.questionId,
                      rules: [item.isMust === 1 ? { required: true } : {}]
                    }}
                    options={{
                      autoComplete: 'off',
                      rows: 4,
                      onChange: () => { this.setState({ activeIndex: index - graphCount }) }
                    }}
                  /> */}
                  <TextArea autoComplete="off" rows={4} showCount maxLength={100} onChange={() => { this.setState({ activeIndex: index - graphCount }) }}/>
                  {/* <Form.Item name={item.questionId} rules={[item.isMust === 1 ? { required: true } : {}]}>
                    
                  </Form.Item> */}
                </div>
              </div>
            </Form.Item>
          )
          break;

        case 3:
          let { rateValue } = this.state;
          const desc = item.optionList.length !== 0 && item.optionList.map(item => item.optionTitle).reverse();
          let value = rateValue[index]
          isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
          dom.push(
            <Form.Item key={item.questionId}>
              <div>
                {this.createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                <div className="q_body" id="rateDiv">
                  <Form.Item name={item.questionId} rules={[item.isMust === 1 ? { required: true } : { required: false }]}>
                    <Rate onChange={(e) => { this.changeRate(e, index, graphCount) }} allowClear={false} value={value} />
                  </Form.Item>
                  {value ? <span className="ant-rate-text">"{desc[value - 1]}"</span> : ''}
                </div>
              </div>
            </Form.Item>
          )
          break;

        case 4:
          isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
          dom.push(
            <Form.Item key={item.questionId} name={item.questionId} rules={[item.isMust === 1 ? { required: true } : {}]}>
              <div>
                {this.createQuestionTitle(item, isBeforeGraph ? index : index - graphCount)}
                <div className="q_body">
                  {/* <MaxLengthInput
                    maxLength={20}
                    formOptions={{
                      name: item.questionId,
                      rules: [item.isMust === 1 ? { required: true } : {}]
                    }}
                    options={{
                      autoComplete: 'off',
                      onChange: () => { this.setState({ activeIndex: index - graphCount }) }
                    }} /> */}
                  {/* <Form.Item name={item.questionId} rules={[item.isMust === 1 ? { required: true } : {}]}> */}
                    <Input
                    autoComplete="off"
                    maxLength={20}
                    onChange={() => { this.setState({ activeIndex: index - graphCount }) }}
                    suffix={<span style={{color:'#888'}}>{this.questionForm?.current?.getFieldValue(item.questionId)?.length||0}/20</span>}
                    />
                    {}
                  {/* </Form.Item> */}
                </div>
              </div>
            </Form.Item>
          )
          break;
        case 5:
          graphCount += 1
          dom.push(
            <div className="questionParagraph" key={item.questionId}>
              {item.questionTitleList && item.questionTitleList.title}
              <div>
                {
                  item.questionTitleList.imgList.map(v => {
                    return <Image id={v.imgId} suffix={v.suffix} imgOption={{ width: '80px', height: '80px' }} type='s' />
                  })
                }
              </div>
            </div>
          )
          break;

        default:
          break;
      }
    });
    return <div>
      <Form
        ref={this.questionForm}
        name="questionForm"
        onValuesChange={this.formValueChange}
        validateMessages={validateMessages}
      >
        {dom}
      </Form>
    </div>
  }

  // 表单修改
  formValueChange = (values, allValues) => {
    let list = []
    for (const key in allValues) {
      if (!(allValues[key] === undefined || allValues[key] === "" || allValues[key].length === 0)) {
        list.push(key)
      }
    }
    setTimeout(() => {
      this.setState({
        answerCardList: list
      })
    }, 700)
  }

  // 提交
  formSubmit = () => {
    const { beginTime, libId, modId, sysFlag } = this.state
    const { current } = this.questionForm
    current.validateFields().then(values => {
      let params = {
        libId,
        modId,
        channel: 'YL',
        beginTime,
        endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        sysFlag,
        timeLong: this.countDownRef.current.countTime
      }
      let answerData = []
      for (const key in values) {
        let answer = values[key] instanceof Array ? values[key].join(',') : values[key]
        answerData.push({
          questionId: key,
          answer
        })
      }
      params.answerData = answerData
      let temHead = localStorage.getItem('__________cccccckkkkkkkkkkk_______');
      let obj = '';
      if (temHead) {
        obj = {
          key: '__________cccccckkkkkkkkkkk_______',
          value: temHead
        }
      }
      getSubmitQuestion({ ...params, ck: localStorage.getItem("__________cccccckkkkkkkkkkk_______") ? localStorage.getItem("__________cccccckkkkkkkkkkk_______") : '' }).then(res => {
        //  清除子组件定时器
        clearInterval(this.countDownRef.current.countInterval)
        clearInterval(this.countDownRef.current.timer)
        // if (res.code === 621011) {
        //   message.error(res.message)
        // }
        if (res.code === 0) {
          this.mesSuccess("提交成功");
          this.props.history.push("/submitSuccess")
        } else {
          this.mesWarning(res.message)
        }
      })
    }).catch(errorInfo => {
      console.log(errorInfo);
    })
  }

  // 获取当前时间
  formatDate = (fmt) => {
    let d = new Date();
    let o = {
      "M+": d.getMonth() + 1, //月份
      "d+": d.getDate(), //日
      "h+": d.getHours(), //小时
      "m+": d.getMinutes(), //分
      "s+": d.getSeconds(), //秒
      "q+": Math.floor((d.getMonth() + 3) / 3), //季度
      S: d.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (d.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  }

  // 修改评分
  changeRate = (value, index, graphCount) => {
    let { rateValue } = this.state
    rateValue[index] = value
    this.setState({
      rateValue,
      activeIndex: index - graphCount
    })
  }

  // 问卷卡dom
  createQUestionCard = () => {
    const { cardList, answerCardList, graphIndexList } = this.state;
    if (cardList.length === 0) {
      return null
    }
    let isBeforeGraph = true // 对应题目是否在段落说明前
    // 已答list中是否存在对应item（questionId）
    return cardList.map((item, index) => {
      isBeforeGraph = index < Math.min.apply(Math, graphIndexList)
      if (answerCardList.includes(item)) {
        return <div key={'card' + index} style={{}} className={this.state.activeIndex === index ? "cardItem pass activeIndex" : "cardItem pass"}>
          {/* {isBeforeGraph ? index + 1 : index + 1 - graphIndexList.length} */}
          {index + 1}
        </div>
      }
      return <div key={'card' + index} className="cardItem">
        {/* {isBeforeGraph ? index + 1 : index + 1 - graphIndexList.length} */}
        {index + 1}
      </div>
    })
  }

  // 毫秒格式化时分秒
  secondToTime = m => {
    var time = parseFloat(m) / 1000;
    if (null != time && "" != time) {
      if (time > 60 && time < 60 * 60) {
        var a = parseInt(time / 60.0) < 10 ? ('0' + parseInt(time / 60.0)) : parseInt(time / 60.0);
        var ms = parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60);
        var b = (ms < 10) ? ('0' + ms) : ms;
        time = '00:' + a + ':' + b;
      }
      else if (time >= 60 * 60 && time < 60 * 60 * 24) {
        var H = parseInt(time / 3600.0) < 10 ? ('0' + parseInt(time / 3600.0)) : parseInt(time / 3600.0);
        var ms = parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60);
        var M = (ms < 10) ? ('0' + ms) : ms;
        var ss = parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60);
        var S = (ss < 10) ? ('0' + ss) : ss;
        time = H + ':' + M + ':' + S;
      }
      else {
        var d = parseInt(time) < 10 ? ('0' + parseInt(time)) : parseInt(time);
        time = '00:00:' + d;
      }
    }
    return time
  }

  // 倒计时结束
  countFinish = () => {
  }

  stopResearch = () => {
    let self = this
    Modal.warning({
      title: '本调研已结束！',
      content: '',
      onOk() {
        // 跳转
        clearInterval(self.countDownRef.current.countInterval)
        clearInterval(self.countDownRef.current.timer)
      }
    });
  }

  render() {
    // const {countPercent, countFlag, isCountDown} = this.state
    const { title, introduction, questionList, countDown } = this.state
    return (
      <div className="question">
        <div style={{ zIndex: 2 }}>
          <HeaderText handClick={() => this.handClick()} isnone={true} CenterText={'问卷详情'}></HeaderText>
        </div>
        <div className="questionHeader">
          <div className="title">
            {title}
          </div>
          <div className="introduction">
            {introduction}
          </div>
        </div>
        <div className="questionBody">
          <div className="body_left">
            {this.createQuestion(questionList)}
          </div>
          <div className="body_right">
            <div className="count">
              <div>
                问卷填写计时器
              </div>
              <div>
                <AnswerCountDown ref={this.countDownRef} countDown={countDown} />
              </div>
              <div>
                <Button type="primary" size="large" onClick={this.formSubmit}>提交问卷</Button>
              </div>
            </div>
            <div className="card">
              <div className="cardTitle">问卷卡</div>
              <div className="cardBody">
                {this.createQUestionCard()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AnswerQuestion
