import React, { Component } from 'react';
import HeaderAction from '../../../components/headerAction'
import { message, Affix, Switch, Radio, Checkbox, Input, Button, Upload, Modal } from 'antd'
import { templateConfig, templateRelease } from '../../../../src/api/Moduleset/index'
import { fujian } from '../../../api/uploadFile'
import Crop from '../../../components/QN_headLook/index';
import './index.less'
const Phone = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M11.2 0H4.80003C3.56489 0 2.56006 1.00483 2.56006 2.23997V13.7598C2.56006 14.995 3.56489 15.9998 4.80003 15.9998H11.2C12.4351 15.9998 13.4399 14.995 13.4399 13.7598V2.23997C13.4399 1.00483 12.4351 0 11.2 0ZM12.1599 13.7598C12.1599 14.2892 11.7293 14.7198 11.2 14.7198H4.80003C4.27067 14.7198 3.84006 14.2892 3.84006 13.7598V2.23997C3.84006 1.71061 4.27068 1.27998 4.80003 1.27998H11.2C11.7293 1.27998 12.1599 1.71061 12.1599 2.23997V13.7598Z" fill="#888888" />
            <path d="M5.91992 2.72007C5.91992 2.36661 6.20646 2.08008 6.55991 2.08008H9.43987C9.79333 2.08008 10.0799 2.36661 10.0799 2.72007C10.0799 3.07353 9.79333 3.36006 9.43987 3.36006H6.55991C6.20646 3.36006 5.91992 3.07353 5.91992 2.72007Z" fill="#888888" />
            <path d="M7.2002 13.1203C7.2002 13.2254 7.22089 13.3294 7.26109 13.4264C7.30129 13.5235 7.36022 13.6117 7.43451 13.686C7.50879 13.7603 7.59698 13.8192 7.69404 13.8594C7.7911 13.8996 7.89513 13.9203 8.00018 13.9203C8.10524 13.9203 8.20926 13.8996 8.30632 13.8594C8.40338 13.8192 8.49157 13.7603 8.56586 13.686C8.64014 13.6117 8.69907 13.5235 8.73927 13.4264C8.77947 13.3294 8.80017 13.2254 8.80017 13.1203C8.80017 12.9081 8.71588 12.7047 8.56586 12.5546C8.41583 12.4046 8.21235 12.3203 8.00018 12.3203C7.78801 12.3203 7.58453 12.4046 7.43451 12.5546C7.28448 12.7047 7.2002 12.9081 7.2002 13.1203Z" fill="#888888" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
}
const Pc = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M0 3.55621C0 2.3291 0.976889 1.33398 2.18222 1.33398H13.8178C15.0236 1.33398 16 2.3291 16 3.55621V9.92643C16 11.154 15.0231 12.1487 13.8178 12.1487H2.18222C0.976889 12.1487 0 11.154 0 9.92643V3.55621ZM2.18222 2.81532C1.78 2.81532 1.45467 3.14732 1.45467 3.55621V9.92643C1.45467 10.3353 1.78 10.6673 2.18178 10.6673H13.8182C14.2196 10.6673 14.5453 10.3358 14.5453 9.92643V3.55621C14.5453 3.14732 14.2196 2.81532 13.8182 2.81532H2.18178H2.18222ZM0 13.9264C0 13.5175 0.325778 13.186 0.727111 13.186H15.2724C15.5324 13.186 15.7724 13.3269 15.9027 13.5562C15.9666 13.6692 16.0002 13.7968 16.0002 13.9267C16.0002 14.0565 15.9666 14.1841 15.9027 14.2971C15.8398 14.4091 15.7484 14.5024 15.6376 14.5675C15.5269 14.6325 15.4009 14.667 15.2724 14.6673H0.727111C0.325778 14.6673 0 14.3358 0 13.9264Z" fill="#888888" />
            <path d="M7.11108 4.22135C7.11108 3.85335 7.44264 3.55469 7.85197 3.55469H8.59286C9.00175 3.55469 9.33331 3.85335 9.33331 4.22135C9.33331 4.58935 9.00175 4.88802 8.59286 4.88802H7.85197C7.44308 4.88802 7.11108 4.58935 7.11108 4.22135Z" fill="#888888" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
}
const Hook = (prop) => {
    const { color } = prop
    return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="26" height="26" rx="13" fill="white" />
        <path d="M7.62611 14.0843C7.23559 13.6937 7.23559 13.0606 7.62611 12.6701C8.01664 12.2795 8.6498 12.2795 9.04032 12.6701L7.62611 14.0843ZM12.458 17.502L13.1651 18.2091C12.7746 18.5996 12.1414 18.5996 11.7509 18.2091L12.458 17.502ZM18.3506 10.1952C18.7411 9.80466 19.3743 9.80466 19.7648 10.1952C20.1553 10.5857 20.1553 11.2189 19.7648 11.6094L18.3506 10.1952ZM9.04032 12.6701L13.1651 16.7948L11.7509 18.2091L7.62611 14.0843L9.04032 12.6701ZM11.7509 16.7948L18.3506 10.1952L19.7648 11.6094L13.1651 18.2091L11.7509 16.7948Z" fill={color} />
    </svg>
}
const Add = () => {
    return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.66">
            <path d="M23.1872 14.1221H15.8895V6.82441C15.8895 6.3208 15.4808 5.91211 14.9772 5.91211C14.4736 5.91211 14.0649 6.3208 14.0649 6.82441V14.1221H6.76715C6.26353 14.1221 5.85484 14.5308 5.85484 15.0344C5.85484 15.5381 6.26353 15.9467 6.76715 15.9467H14.0649V23.2445C14.0649 23.7491 14.4736 24.1568 14.9772 24.1568C15.4808 24.1568 15.8895 23.7491 15.8895 23.2445V15.9467H23.1872C23.6918 15.9467 24.0995 15.5381 24.0995 15.0344C24.0995 14.5308 23.6918 14.1221 23.1872 14.1221Z" fill="#3E78ED" />
        </g>
    </svg>
}
const LeftIcon = () => {
    return <>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00642 4.01977H1.73841L5.61202 0.91489C5.66958 0.876625 5.71858 0.826822 5.75589 0.768635C5.7932 0.710448 5.81802 0.645146 5.82878 0.576865C5.83954 0.508585 5.836 0.438814 5.81838 0.371973C5.80077 0.305132 5.76947 0.242678 5.72645 0.188567C5.68344 0.134456 5.62966 0.0898674 5.56852 0.0576263C5.50738 0.0253852 5.4402 0.00619414 5.37126 0.00126885C5.30231 -0.00365644 5.23309 0.0057914 5.16798 0.0290139C5.10288 0.0522363 5.04331 0.0887272 4.99304 0.136175L0.00127564 4.12959V4.30929C-0.000425212 4.33255 -0.000425212 4.35591 0.00127564 4.37917V4.42909C-0.000425212 4.45236 -0.000425212 4.47571 0.00127564 4.49898V4.67868L4.99304 8.67209C5.04417 8.71339 5.10294 8.74421 5.16598 8.7628C5.22902 8.78139 5.29511 8.78737 5.36047 8.78042C5.42582 8.77347 5.48917 8.75371 5.54689 8.72228C5.60462 8.69084 5.65558 8.64835 5.69688 8.59721C5.73818 8.54608 5.769 8.48732 5.78759 8.42428C5.80617 8.36123 5.81216 8.29515 5.80521 8.22979C5.79826 8.16443 5.7785 8.10108 5.74706 8.04336C5.71563 7.98564 5.67313 7.93467 5.622 7.89338L1.73841 5.01812H9.00642C12.1912 5.01812 14.7869 7.25443 14.7869 10.0099C14.7869 12.7653 12.1912 15.0016 9.00642 15.0016H1.30912C1.17673 15.0016 1.04976 15.0542 0.956147 15.1479C0.862533 15.2415 0.809942 15.3684 0.809942 15.5008C0.809942 15.6332 0.862533 15.7602 0.956147 15.8538C1.04976 15.9474 1.17673 16 1.30912 16H9.00642C12.7902 16 15.7852 13.3144 15.7852 10.0099C15.7852 6.70534 12.7902 4.01977 9.00642 4.01977Z" fill="#333333" />
        </svg>
        <span> &nbsp;
        返回
         </span>
    </>

}

const CutSet = {
    //aspect:160/88,
    resize: true, //裁剪是否可以调整大小
    resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
    modalTitle: "上传图片", //弹窗标题
    modalWidth: 800, //弹窗宽度
    rotate: true,
    width: 160,  //裁剪宽度
    height: 88 //裁剪高度
}

const { TextArea } = Input;
function _get_query(name) {
    const search = window.location.href

    if (!search) return null

    let item = search.split('?')[1];
    if (!item) return null;
    if (item.indexOf("&") !== -1) {
        item = item.split("&")
    } else {
        item = [item]
    }

    const fi = item.find(v => v.includes(name)) ? item.find(v => v.includes(name)).split('=')[1] : ''
    if (item) {
        return fi
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}
const cropOptions = {
    minWidth: 80,   //裁剪框的最小宽
    minHeight: 80,   //裁剪框的最小高
    keepSelection: true
}
class ModuleSet extends Component {

    state = {
        coverUrl: '',
        // 初始背景图片
        initUrl: '',
        // 初始内容图片
        initApp: "base/qs/image/cf00a2072d8a4b22b9c44cce806d7d36.jpg",
        initPc: "base/qs/image/a74eec7613da4d82b08b08c580183660.jpg",
        themeInfo: {},
        // 用于模型的index
        typeIndex: 0,
        RouterTab: [{
            name: "编辑内容",
            path: `/editQuestion/editContent?libid=${JSON.parse(sessionStorage.getItem("router")).result}&title=${JSON.parse(sessionStorage.getItem("router")).questionTitle}`
        }, {
            name: "高级设置",
            path: "/editQuestion/heightSet"
        }
        // , {
        //     name: "模板设置",
        //     path: "/editQuestion/moduleSet",
        // }
        ],
        currentTanIndex: 2,
        // 控制图片上传前后显示
        cutover: false,
        fileName: '',
        isBlock: false,
        baseImgUrl: 0,
        src: '',
    }

    componentDidMount() {
        this.getThemeInfo()
    }
    // 上传头像
    uploadChange = e => {
        let { file } = e;
        this.setState({
            fileObj: file,
            fileName: file.name,
            isBlock: true
        }, () => {
            let reader = new FileReader();
            reader.onloadend = (e) => {
                this.setState({ src: e.target.result });
                let img = new Image();
                img.src = e.target.result;
            }
            reader.readAsDataURL(file.originFileObj)
        })
    }
    // 保存头像
    saves = async file => {
        const { themeInfo: { themeList } } = this.state
        const formdata = new FormData()
        formdata.append('file', file)
        formdata.append('folder', 'qs')
        const res = await fujian(formdata);
        if (res.code === 0) {
            themeList.map(v => v.isDefault = 0)
            this.setState(() => {
                return {
                    coverUrl: res.result.fpName,
                    initUrl: res.result.fpName,
                    cutover: true,
                    themeInfo: this.state.themeInfo,
                    isBlock: false,
                    baseImgUrl: 1
                }
            })
        } else {
            if (res.code === 681002 || res.code === 681003 || res.code === 681004) {
                this.mesWarning(res.message)
            } else {
                this.mesWarning("图片上传出错")
            }
        }
    }
    getThemeInfo = async () => {
        let { initUrl, initApp, initPc, coverUrl } = this.state;
        let res = await templateConfig({ libId: _get_query("libid") });
        if (res) {
            if (res.code !== 500) {
                const index = res.result.themeList.findIndex(v => v.isDefault === 1)
                if (index !== -1 && index !== 0) {
                    initUrl = res.result.themeList[index].themeUrl
                }
                if (res.result.themeType) {
                    initUrl = res.result.coverUrl
                    coverUrl = res.result.coverUrl
                    this.setState({
                        baseImgUrl: 1
                    })
                }
                const m = res.result.colorList.findIndex(v => v.isDefault === 1)
                if (m !== -1) {
                    initApp = res.result.colorList[m].appUrl
                    initPc = res.result.colorList[m].pcUrl
                }
                this.setState(() => ({
                    themeInfo: res.result,
                    initUrl, coverUrl, initApp, initPc
                }))
            } else {
                this.mesWarning("攻城狮正在赶来")
            }

        } else {
            this.mesWarning("数据错误")
        }
    }
    // 返回按钮
    handClick = () => {
        this.props.history.push('/main')
    }
    // 展示手机模型
    phoneShow = () => {
        this.setState(() => ({
            typeIndex: 0
        }))
    }
    // 展示电脑模型
    pcShow = () => {
        this.setState(() => ({
            typeIndex: 1
        }))
    }
    //左边按钮列表切换
    changeModule = (v) => {
        const { themeInfo: { themeList }, } = this.state
        themeList.map((item, index) => {
            return item.isDefault = 0
        })
        const m = themeList.findIndex(key => {
            return key === v
        })
        themeList[m].isDefault = 1;
        if (m !== 0) {
            this.setState(() => {
                return {
                    initUrl: v.themeUrl,
                    coverUrl: '',
                    cutover: false,
                    baseImgUrl: 0
                }
            })
        } else {
            this.setState(() => {
                return {
                    initUrl: "",
                    coverUrl: '',
                    cutover: false,
                    baseImgUrl: 0
                }
            })
        }

    }
    changeBtn = (v) => {
        const { themeInfo: { colorList } } = this.state
        colorList.map((item, index) => {
            return item.isDefault = 0
        })
        const n = colorList.findIndex(key => {
            return key === v
        })
        colorList[n].isDefault = 1;
        this.setState(() => {
            return {
                initApp: v.appUrl,
                initPc: v.pcUrl
            }
        })
    }
    //预览按钮
    preview = () => {
        const { initUrl, initApp, initPc, baseImgUrl } = this.state
        const data = { initUrl, baseImgUrl, initApp, initPc, libId: JSON.parse(sessionStorage.getItem("router")).result }
        window.sessionStorage.setItem("modulePreviewData", JSON.stringify(data))
        window.open("/modulePreview")
        // this.props.history.push({ pathname: "/modulePreview", state: { initUrl, initApp, initPc, libId: JSON.parse(sessionStorage.getItem("router")).result } })
    }
    // 保存按钮
    save = async () => {
        const { coverUrl, themeInfo: { colorList, themeList } } = this.state
        // let data = {}
        const n = colorList.findIndex(key => {
            return key.isDefault === 1
        })
        const m = themeList.findIndex(key => {
            return key.isDefault === 1
        })

        let data = { coverUrl, colorName: colorList[n].colorName, libId: _get_query("libid") }
        if (m === -1) {
            data.themeName = ''
        } else {
            data.themeName = themeList[m].themeName
        }
        if (coverUrl === '') {
            data.themeType = 0
        } else {
            data.themeType = 1
        }


        let res = await templateRelease(data)
        if (res) {
            if (res.code === 0) {
                this.mesSuccess("保存成功")
                this.props.history.push('/main')
            } else {
                this.mesWarning(res.message);

            }
        } else {
            this.mesWarning("数据错误")
        }
    }
    //顶部中间切换
    go = (v, i) => {
        this.props.history.push(v.path)
        this.setState(() => {
            window.sessionStorage.currentTanIndex = i
            return {
                currentTanIndex: i
            }
        })

    }

    handleChange = async (info) => {
        const { themeInfo: { themeList } } = this.state
        const formdata = new FormData()
        formdata.append('file', info.file)
        formdata.append('folder', 'qs')
        const res = await fujian(formdata);
        if (res) {
            themeList.map(v => v.isDefault = 0)
            this.setState(() => {
                return {
                    coverUrl: res.result.fpName,
                    initUrl: res.result.fpName,
                    cutover: true,
                    themeInfo: this.state.themeInfo
                }
            })
        } else {
            this.mesWarning("图片上传出错")
        }

    }

    imgSrcFn = {
        0: window.$$setImgSrc,
        1: window.$$uploadRc,
    }

    render() {
        const { themeInfo: { themeList, colorList }, initUrl, initApp, initPc, RouterTab, currentTanIndex, cutover, baseImgUrl } = this.state;

        return (
            <div className='ModuleSet'>
                <div className='headerAction'>
                    <div className="headerActionleft" onClick={() => this.handClick()} >
                        <LeftIcon />
                    </div>
                    <div className="headerActionright">
                        <div className="uul">
                            {RouterTab.map((v, i) => {
                                return <div key={v.path} className={`item ${currentTanIndex === i ? 'active' : ''}`} onClick={() => this.go(v, i)}>
                                    {v.name}
                                </div>
                            })}
                        </div>
                        <div className="rrl">
                            <div className="item" onClick={() => this.save()}>
                                <Button type='primary'>保存</Button>
                            </div>
                            <div className="item" onClick={() => this.preview()}>
                                <Button style={{ borderColor: "#3E78ED", color: "#3E78ED" }}>预览</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m_left">
                    <Affix offsetTop={82} >
                        <div className="module_set">
                            <div className="h">
                                模板设置
                            </div>
                            <div className="theme_list">
                                {Array.isArray(themeList) && themeList.map((v, i) => {
                                    return <div className={`temp ${v.isDefault === 1 ? 'active' : ''}`} key={i} onClick={() => this.changeModule(v)}>
                                        <div className="img">
                                            <img src={window.$$setImgSrc(v.themeUrl)} alt="" /></div>
                                        <div className="t_name">{v.themeName}</div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </Affix>
                </div>
                <div className="m_center">
                    <div className="switch_btn">
                        <div className={`icon_c ${this.state.typeIndex === 0 ? 'active' : ''}`} onClick={() => this.phoneShow()}>
                            <Phone />
                            <span>手机</span>
                        </div>
                        <div className="line"></div>
                        <div className={`icon_c ${this.state.typeIndex === 1 ? 'active' : ''}`} onClick={() => this.pcShow()}>
                            <Pc />
                            <span>电脑</span>
                        </div>
                    </div>
                    <div className="module_show">
                        {
                            !this.state.typeIndex ?
                                <div className="phone" style={{ background: `url(${window.$$setImgSrc('QN_phoneStyle.png')})`, backgroundRepeat: "no-repeat", backgroundSize: "315px 593px" }}>
                                    <div className="ph_bg" style={{ background: `url(${window.$$setImgSrc('QN_phoneBanner.png')})`, backgroundRepeat: "no-repeat", backgroundSize: "253px 542px" }}>
                                        <div className="scroll">
                                            {initUrl ? <div className="ph_banner">
                                                <img src={this.imgSrcFn[baseImgUrl](initUrl)} alt="" />
                                            </div> : ''}
                                            <div className="ph_content">
                                                <img src={window.$$setImgSrc(initApp)} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="pc">
                                    <div className="pc_scroll">
                                        {initUrl ? <div className="pc_banner">
                                            <img src={this.imgSrcFn[baseImgUrl](initUrl)} alt="" />
                                        </div> : ''}
                                        <div className="pc_content">
                                            <div className="title">餐饮顾客调查问卷</div>
                                            <img src={window.$$setImgSrc(initPc)} alt="" />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className="m_right">
                    <Affix offsetTop={82} >
                        <div className="pf_select">
                            <div className="h">
                                皮肤背景
                            </div>
                            <div className="up_img">
                                <Upload
                                    // customRequest={(e) => this.handleChange(e)}
                                    onChange={(e) => this.uploadChange(e)}
                                    // listType="picture-card"
                                    className="uploader"
                                    showUploadList={false}
                                    accept="image/*"
                                >
                                    {!cutover ?
                                        <div className="img_show">
                                            <Add />
                                            <span>上传图片</span>
                                        </div>
                                        :
                                        <div className="img_show">
                                            <img src={window.$$uploadRc(initUrl)} alt="" />
                                        </div>
                                    }
                                </Upload>
                                <Modal
                                    className='crop_modal'
                                    title='图片裁剪'
                                    visible={this.state.isBlock}
                                    destroyOnClose={true}
                                    footer={null}
                                    closable={false}
                                >
                                    <Crop fileName={this.state.fileName} options={cropOptions} cancelBack={() => this.setState({ isBlock: false })} saveBack={this.saves} src={this.state.src} />
                                </Modal>
                            </div>
                            <div className="bgc_c">
                                <div className="h_t">
                                    皮肤背景
                                </div>
                                {}
                                <div className="btn_list">
                                    {Array.isArray(colorList) && colorList.map((v, i) => {
                                        return <div className="btn_c" key={i} onClick={() => this.changeBtn(v)}>
                                            <span>{v.colorName}</span>
                                            <em style={{ backgroundColor: v.colorValue }} className={v.isDefault ? "on" : "off"}>{v.isDefault ? <Hook color={v.colorValue} /> : ''}</em>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </Affix>
                </div>
            </div>
        );
    }
}

export default ModuleSet;











