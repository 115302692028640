
export default {
    SAVE_previewQuestionList(state, { res }) {
        const { result } = res
        if (result) {
            state.title = result.questionTitle
            state.introduction = result.introduction
            state.questionList = [...result.questionList]
        }
    },
    GET_TEMPLATEINFO(state,{res}){
        const { result } = res
        if (result) {
            state.title = result.questionTitle
            state.introduction = result.introduction
            state.questionList = [...result.questionList]
        }
    }
}
