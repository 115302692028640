import { CHANGE_ACTIVE, GET_BUTTON_ROLE, GET_MENU_ROLE } from './action_type';
import { getButtonRole, getMenu } from '../../../api/role/index.js';


export const changeIndex = (data) => {
    return {
        type: CHANGE_ACTIVE,
        data: data
    }
}

//调用获取按钮权限接口
export const get_button = data => {
    return async (dispatch) => {
        let res = await getButtonRole(data);
        if (res) {
            dispatch({
                type: GET_BUTTON_ROLE,
                data: Array.isArray(res) ? res : []
            })
        }
    }
}

//调用获取菜单权限接口
export const get_menu = data => {
    return async (dispatch) => {
        let res = await getMenu(data);
        if (res) {
            dispatch({
                type: GET_MENU_ROLE,
                data:  Array.isArray(res) ? res : []
            })
        }
    }
}