import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom'
import RouterView from './router/router_view'
import routers from './router/router_config'
import resetAntd from './config/antd_global';
import { connect } from 'react-redux';
import {get_button,get_menu} from './store/WEN_JUAN/main/action.js';
window.elGoTop = function (n) {
  document.querySelector(n) && document.querySelector(n).scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

     
class App extends Component {
  componentDidMount() {
    // this.props.get_button({platform:'CBS'});
    // this.props.get_menu({platform:'CBS'});
  }
  render() {  
    return (
      <BrowserRouter>
        <RouterView routers={routers}></RouterView>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = ({ MAIN }) => {
  const { list } = MAIN;
  return {
    list
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_button(data){
      dispatch(get_button(data));
    },
    get_menu(data){
      dispatch(get_menu(data));
    }
  };
}
App = connect(mapStateToProps, mapDispatchToProps)(App);
App = resetAntd(App)

export default App

