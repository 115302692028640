import LoadableComponent from '../until/LoadableComponent'
let moduleFile = require.context('../page', true, /\index.js$/);
let result = moduleFile.keys().reduce((prev, item) => {
    let str = item.split('/')[item.split('/').length - 2];
    let name = str[0].toLocaleUpperCase() + str.slice(1);
    prev = Object.assign({}, prev, { [name]: LoadableComponent(import('../page' + item.slice(1))) })
    return prev
}, {});
const routerConfig = [{
    name: "首页",
    path: "/",
    redirect: '/main',
}, {
    name: "选择调研",
    path: "/selectreSearch",
    component: result.Selectresearch
},
{
    name: "复制调研",
    path: "/historyQuestion",
    component: result.HistoryQuestion
},
{
    name: "模板预览",
    path: "/modulePreview",
    component: result.ModulePreview
},
{
    name: "主页",
    path: "/main",
    component: result.Main
}, {
    name: "创建",
    path: "/create",
    component: result.Create
}, {
    name: "编辑题目",
    path: "/editQuestion",
    component: result.EditQuestion,
    children: [{
        name: "编辑内容",
        path: "/editQuestion/editContent",
        component: result.EditContent
    }, {
        name: "高级设置",
        path: "/editQuestion/heightSet",
        component: result.HeightSet
    }, {
        name: "模板设置",
        path: "/editQuestion/moduleSet",
        component: result.ModuleSet
    },]
}, {
    name: "无效问卷",
    path: "/invalid",
    component: result.Invalid
}, {
    name: "有效问卷",
    path: "/valid",
    component: result.Valid
}, {
    name: "预览内容",
    path: "/previewQuestion",
    component: result.PreviewQuestion
}, {
    name: "个人详情",
    path: "/details",
    component: result.Details
}, {
    name: "学生答题页面",
    path: "/answerQuestion",
    component: result.AnswerQuestion
},
{
    name: "答题提交成功",
    path: "/submitSuccess",
    component: result.SubmitSuccess
},
{
    name: "无效答卷",
    path: "/invalidanswer",
    component: result.Invalidanswer
}]
export default routerConfig
