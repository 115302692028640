import React, { Component } from 'react'
import { message, Affix, Switch, Radio, Checkbox, Input } from 'antd'
import './index.less'

const { TextArea } = Input;

const Phone = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9991 0H5.99918C4.45525 0 3.19922 1.25604 3.19922 2.79996V17.1998C3.19922 18.7437 4.45525 19.9997 5.99918 19.9997H13.9991C15.543 19.9997 16.799 18.7437 16.799 17.1998V2.79996C16.799 1.25604 15.543 0 13.9991 0ZM15.1991 17.1998C15.1991 17.8615 14.6608 18.3998 13.9991 18.3998H5.99918C5.33748 18.3998 4.79922 17.8615 4.79922 17.1998V2.79996C4.79922 2.13826 5.3375 1.59998 5.99918 1.59998H13.9991C14.6608 1.59998 15.1991 2.13826 15.1991 2.79996V17.1998Z" fill="#888888" />
        <path d="M7.40234 3.3996C7.40234 2.95778 7.76051 2.59961 8.20233 2.59961H11.8023C12.2441 2.59961 12.6023 2.95778 12.6023 3.3996C12.6023 3.84142 12.2441 4.19959 11.8023 4.19959H8.20234C7.76051 4.19959 7.40234 3.84142 7.40234 3.3996Z" fill="#888888" />
        <path d="M9 16.4004C9 16.5317 9.02587 16.6617 9.07612 16.7831C9.12637 16.9044 9.20003 17.0146 9.29289 17.1075C9.38574 17.2003 9.49598 17.274 9.6173 17.3242C9.73863 17.3745 9.86866 17.4004 9.99998 17.4004C10.1313 17.4004 10.2613 17.3745 10.3827 17.3242C10.504 17.274 10.6142 17.2003 10.7071 17.1075C10.7999 17.0146 10.8736 16.9044 10.9238 16.7831C10.9741 16.6617 11 16.5317 11 16.4004C11 16.1352 10.8946 15.8808 10.7071 15.6933C10.5195 15.5057 10.2652 15.4004 9.99998 15.4004C9.73477 15.4004 9.48042 15.5057 9.29289 15.6933C9.10536 15.8808 9 16.1352 9 16.4004Z" fill="#888888" />
    </svg>
}
const Pc = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M0 4.44379C0 2.9099 1.22111 1.66602 2.72778 1.66602H17.2722C18.7794 1.66602 20 2.9099 20 4.44379V12.4066C20 13.941 18.7789 15.1843 17.2722 15.1843H2.72778C1.22111 15.1843 0 13.941 0 12.4066V4.44379ZM2.72778 3.51768C2.225 3.51768 1.81833 3.93268 1.81833 4.44379V12.4066C1.81833 12.9177 2.225 13.3327 2.72722 13.3327H17.2728C17.7744 13.3327 18.1817 12.9182 18.1817 12.4066V4.44379C18.1817 3.93268 17.7744 3.51768 17.2728 3.51768H2.72722H2.72778ZM0 17.4066C0 16.8955 0.407222 16.481 0.908889 16.481H19.0906C19.4156 16.481 19.7156 16.6571 19.8783 16.9438C19.9582 17.085 20.0002 17.2446 20.0002 17.4068C20.0002 17.5691 19.9582 17.7287 19.8783 17.8699C19.7998 18.0099 19.6855 18.1265 19.547 18.2078C19.4086 18.2892 19.2511 18.3322 19.0906 18.3327H0.908889C0.407222 18.3327 0 17.9182 0 17.4066Z" fill="#888888" />
            <path d="M8.88672 5.27669C8.88672 4.81669 9.30116 4.44336 9.81283 4.44336H10.7389C11.2501 4.44336 11.6645 4.81669 11.6645 5.27669C11.6645 5.73669 11.2501 6.11002 10.7389 6.11002H9.81283C9.30172 6.11002 8.88672 5.73669 8.88672 5.27669Z" fill="#888888" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
}
const Back = () => {
    return <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.80453 4.07872C7.80453 3.67047 8.09874 3.33984 8.46099 3.33984H17.0477C18.4622 3.33984 19.6323 4.53776 19.6323 6.05097V17.8787C19.6323 19.3929 18.4631 20.5898 17.0477 20.5898H8.46099C8.09874 20.5898 7.80453 20.2592 7.80453 19.851C7.80453 19.4427 8.09874 19.1111 8.46099 19.1111H17.0477C17.7626 19.1111 18.3184 18.5428 18.3184 17.8787V6.05097C18.3184 5.38684 17.7626 4.81855 17.0477 4.81855H8.46099C8.09874 4.81855 7.80453 4.48697 7.80453 4.07872ZM3.51312 11.5738L3.58499 11.4914L7.52757 7.54884C7.65902 7.41501 7.83645 7.33619 8.02387 7.32835C8.2113 7.32052 8.39468 7.38427 8.53684 7.50668C8.67899 7.62908 8.76926 7.80097 8.78935 7.98748C8.80943 8.17399 8.75782 8.36115 8.64499 8.51101L8.57312 8.59439L5.89362 11.2748H13.2747C13.498 11.2748 13.682 11.548 13.7136 11.9045L13.7184 12.0137C13.7184 12.3856 13.5545 12.6922 13.3408 12.7449L13.2747 12.7536H5.89266L8.57312 15.434C8.69848 15.5593 8.7747 15.7255 8.78789 15.9022C8.80108 16.079 8.75037 16.2546 8.64499 16.3971L8.57312 16.4796C8.44787 16.6047 8.2819 16.6807 8.10536 16.6939C7.92882 16.7071 7.7534 16.6566 7.61095 16.5514L7.52757 16.4796L3.58499 12.537C3.45962 12.4117 3.38341 12.2455 3.37022 12.0688C3.35703 11.892 3.40773 11.7164 3.51312 11.5738Z" fill="#333333" />
    </svg>
}
export default class index extends Component {
    state = {
        typeIndex: 0,
        initUrl: "",
        initApp: "",
        initPc: "",
        baseImgUrl:0
    }
    componentDidMount() {
        console.log(this.props);
        // const { initApp, initPc, initUrl } = this.props.location.state
        const { initApp, initPc, initUrl,baseImgUrl } = JSON.parse(window.sessionStorage.getItem("modulePreviewData"))
        this.setState(() => {
            return {
               initUrl,initPc,initApp,baseImgUrl
            }
        })

    }
    // 展示手机模型
    phoneShow = () => {
        this.setState(() => ({
            typeIndex: 0
        }))
    }
    // 展示电脑模型
    pcShow = () => {
        this.setState(() => ({
            typeIndex: 1
        }))
    }
    imgSrcFn = {
        0: window.$$setImgSrc,
        1: window.$$uploadRc,
    }
    //退出预览
    // quitPreview = () => {
    //     this.props.history.push(`/editQuestion/moduleSet?libid=${this.props.location.state.libId}`)
    // }
    render() {
        const { initUrl,initPc,initApp,baseImgUrl } = this.state
        return (
            <div className="mudule_preview">
                <div className="headerText">
                    {/* <div className="quit_pre" onClick={() => this.quitPreview()}>
                        <Back /><span>退出预览</span>
                    </div> */}
                    <div className="h_total">
                        提示：当前为预览页，操作不被记录
                    </div>
                </div>
                <div className="m_center">
                    <div className="switch_btn">
                        <div className={`icon_c ${this.state.typeIndex === 0 ? 'active' : ''}`} onClick={() => this.phoneShow()}>
                            <Phone />
                            <span>手机</span>
                        </div>
                        <div className="line"></div>
                        <div className={`icon_c ${this.state.typeIndex === 1 ? 'active' : ''}`} onClick={() => this.pcShow()}>
                            <Pc />
                            <span>电脑</span>
                        </div>
                    </div>
                    <div className="module_show">
                        {
                            !this.state.typeIndex ?
                                <div className="phone" style={{ background: `url(${window.$$setImgSrc('QN_phoneStyle.png')})`, backgroundRepeat: "no-repeat", backgroundSize: "372px 700px" }}>
                                    <div className="ph_bg" style={{ background: `url(${window.$$setImgSrc('QN_phoneBanner.png')})`, backgroundRepeat: "no-repeat", backgroundSize: "296px 644px" }}>
                                        <div className="scroll">
                                            {initUrl ?
                                                <div className="ph_banner">
                                                    <img src={this.imgSrcFn[baseImgUrl](initUrl)} alt="" />
                                                </div>
                                                : ''}
                                            <div className="ph_content">
                                                <img src={window.$$setImgSrc(initApp)} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="pc">
                                    <div className="pc_scroll">
                                        {initUrl ?
                                            <div className="pc_banner">
                                                <img src={this.imgSrcFn[baseImgUrl](initUrl)} alt="" />
                                            </div>
                                            : ''}
                                        <div className="pc_content">
                                            <div className="title">餐饮顾客调查问卷</div>
                                            <img src={window.$$setImgSrc(initPc)} alt="" />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

