import React from 'react';
import ReactDOM from 'react-dom';
import './index.less'
import App from './App';
import '../src/components/confirm/index'
// import RenderButton from '@/components/renderButton';
import store from './store'
import { Provider } from 'react-redux'
import { getImageOssBaseurl } from "./api/Baseurl";
import { baseURL, initSecretUrlByType07 } from './config/secret';
import Http from './until/axios_instans';
// React.RenderButton = RenderButton;
function create(src) {
    return function (path) {
        if (!path) return;
        if (path.indexOf("/") === 0) {
            return src + path;
        } else {
            return src + path;
        }
    }
}




getImageOssBaseurl({ type: '100007' }).then(res => {

    const {
        'static-img': staticImg,
        'front-img': frontImg,
        'public-resource': publicResource,
        'favicon': favicon,
        'title': title
    } = res.result.config
    window.$$setImgSrc = create(staticImg);
    window.$$img = create(frontImg);
    window.$$uploadRc = create(publicResource);
    window.favicon = window.$$setImgSrc(favicon)
    let title_icon = document.getElementsByTagName("link")[0];
    document.title = title.QN || '调查问卷'
    title_icon.href = window.favicon
    if (res?.result?.config) {
        initSecretUrlByType07(res?.result?.config);
        Http.defaults.baseURL = baseURL;
    }
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>
        ,
        document.getElementById('root')
    );
})








