import { post } from '../../until/axios_instans';

//上传文件
export function fujian(data){
    return post('/api/fujian/api/v1/fujian/uploadFile',data);
}





