import { post } from '../../until/axios_instans'
// 问卷表格上面的数据
export const getResearchHeader = (data) => post('/api/wenjuan/api/v1/wenjuan/answerHeader', data);

// 关联内容list
export const getRelatedList = (data) => post('/api/wenjuan/api/v1/wenjuan/relatedContent', data);

// 表格数据
export const getTableData = (data) => post('/api/wenjuan/api/v1/wenjuan/validQuestionList', data);

// 延长收集时间
export const getExtension = (data) => post('/api/wenjuan/api/v1/answer/extension', data);

// 标记无效
export const getChangeInvalid = (data) => post('/api/wenjuan/api/v1/wenjuan/batchInValid', data);

// 确认正常
export const getConfirmNormal = (data) => post('/api/wenjuan/api/v1/wenjuan/batchNormal', data);

// 启动暂停收集
export const getChangeCollection = (data) => post('/api/wenjuan/api/v1/wenjuan/changeCollection', data);