import {
    SAVE_previewQuestionList,
    GET_TEMPLATEINFO
} from './action_type';
import { getPreviewQuestionList } from '../../../api/previewQuestion';
import { editTemplate } from '../../../api/Homeinterface/index.js';

// 保存预览数据
export const fetchPreviewQuestionList = data => {
    return async dispatch => {
        let res = await getPreviewQuestionList(data)
        if (res.message === 'success') {
            dispatch({
                type: SAVE_previewQuestionList,
                res
            })
        }
    }
}

//编辑的时候的保存
export const _edit_PreviewQuestionList = send_obj => {
    return async dispatch => {
       
        dispatch({
            type: SAVE_previewQuestionList,
            res:{
                result:send_obj
            }
        })
    }
}


//使用templateId获取数据回显
export const getTemplateInfo = data=>{
    return async dispatch=>{
        let res  = await editTemplate(data);
        dispatch({
            type:GET_TEMPLATEINFO,
            res
        })
    }
}