import React from 'react'
import { message, Col, Select, DatePicker, Divider, Checkbox, Button, Table, Modal, Input } from 'antd';
import moment from 'moment';
import { getRelatedList, getTableData, getExtension, getChangeInvalid, getConfirmNormal, getResearchHeader, getChangeCollection } from '../../api/valid/index';
import HeaderText from '../../components/headerText'
import './index.less';

function _get_query(name) {
  const search = window.location.href

  if (!search) return null
  const item = search.split('?').find(v => {
    return v.includes(name)
  });
  if (item) {
    const _item_arr = item.split('=')
    return _item_arr[1]
  } else {
    console.log(`${name} is not in search`)
    return null
  }
}
const { Option } = Select;
class ValidQuestionList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      libId: _get_query('libid'),
      selectedRowKeyList: [],
      selectedRowList: [],
      delayVisible: false,
      invalidVisible: false,
      allSelectFlag: false,
      relatedSelect: "", // 筛选条件关联内容
      questionStatus: "", // 筛选条件答题状态
      startTime: '', // 筛选条件开始时间

      title: '',
      researchType: '',
      lastUpdateTime: '',
      researchCount: '0',
      collectStatus: 1, // 1采集中 2暂停中
      delayValue: '', // 延长延迟时间
      invalidValue: '', // 无效原因
      selectAnswerId: [], // 操作点击标记无效时存放对应id
      relatedList: [],
      columns: [
        {
          title: '序号', dataIndex: 'number', key: 'number', align: 'center'
        },
        {
          title: '答卷ID', dataIndex: 'answerId', key: 'answerId', align: 'center',
          render: (text, _) => {
            return <div className="tableRelated" title={text}>{text}</div>
          }
        },
        {
          title: '答卷状态', dataIndex: 'answerStatus', key: 'answerStatus', align: 'center',
          render: (text, _) => {
            // 正常0  待确认1
            return text === 0 ? <span>正常</span> : <span style={{ color: '#E75301' }}>待确认</span>
          }
        },
        {
          title: '开始时间', dataIndex: 'createTime', key: 'createTime', align: 'center',
          render: (text, _) => {
            return moment(text).format("YYYY-MM-DD HH:mm")
          }
        },
        {
          title: '答题时长', dataIndex: 'timeLong', key: 'timeLong', align: 'center',
          render: (text, _) => {
            return this.formatDuring(text)
          }
        },
        {
          title: '关联内容', dataIndex: 'linkObj', key: 'linkObj', align: 'center',
          render: (text, _) => {
            return <div className="tableRelated" title={text}>{text||'--'}</div>
          }
        },
        {
          title: '答题者', dataIndex: 'userName', key: 'userName', align: 'center',
          render: (text, _) => {
            return <div className="tableRelated" title={text}>{text||'--'}</div>
          }
        },
        {
          title: '操作', dataIndex: 'number', key: 'number', align: 'center',
          render: (text, record) => {
            return <span key={record.answerId}>
              <a style={{ visibility: record.answerStatus !== 1 ? 'hidden' : 'visible' }} onClick={() => this.confirm(record.answerId)}>确认正常</a>
              <Divider style={{ visibility: record.answerStatus !== 1 ? 'hidden' : 'visible' }} type="vertical" />
              <a onClick={() => this.signInvalid(record.answerId)}>标记无效</a>
              <Divider type="vertical" />
              <a onClick={() => this.openDetail(record.answerId)}>查看</a>
            </span>
          }
        }
      ],
      dataSource: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
    }
  }

  componentDidMount() {
    this.initData()
  }

  initData = () => {
    let { relatedList, pageNum, pageSize, libId } = this.state
    getResearchHeader({ libId }).then(res => {
      if (res && res.message === "success") {
        const { result } = res
        this.setState({
          title: result.questionTitle,
          researchType: result.type,
          lastUpdateTime: moment(result.updateTime).format('YYYY-MM-DD HH:mm'),
          researchCount: result.submitNum || 0,
          collectStatus: result.status,
        })
      }
    })

    getRelatedList({}).then(res => {
      if (res && res.message === "success") {
        let params = {
          libId,
          pageSize,
          pageNum,
          answerStatus: '',
          startTime: '',
        }
        relatedList = [...res.result]
        relatedList.unshift({
          linkId: "",
          linkTitle: "全部"
        })
        let related = relatedList[0].linkId
        params.related = related

        getTableData(params).then(t_res => {
          if (t_res && t_res.message === 'success') {
            let { data, total } = t_res.result
            let dataSource = []
            data.length && data.forEach((item, index) => {
              // 序号处理
              item.number = pageSize * (pageNum - 1) + index + 1
              dataSource.push(item)
            })
            this.setState({
              relatedList,
              dataSource,
              total
            })
          }
        })
      }
    })
  }

  // 改变参数重新请求表格
  requestTable = () => {
    const { libId, pageSize, pageNum, relatedSelect, startTime, questionStatus } = this.state
    let params = {
      libId,
      pageSize,
      pageNum,
      related: relatedSelect,
      answerStatus: questionStatus,
      startTime,
    }
    getTableData(params).then(t_res => {
      if (t_res && t_res.message === 'success') {
        let { data, total } = t_res.result
        let dataSource = []
        data.length && data.forEach((item, index) => {
          // 序号处理
          item.number = pageSize * (pageNum - 1) + index + 1
          dataSource.push(item)
        })
        this.setState({
          dataSource,
          total
        })
      }
    })
  }

  // 根据类型返回不同答卷类型dom
  returnResearchType = type => {
    if (type === '0') {
      // 问卷
      return <div className="researchtype" style={{ background: '#ECF2FD', color: '#3E78ED' }}>问卷</div>
    } else if (type === '1') {
      // 反馈
      return <div className="researchtype" style={{ background: '#FDEEE6', color: '#E75301' }}>反馈</div>
    } else if (type === '2') {
      // 投票
      return <div className="researchtype" style={{ background: '#ECFAF5', color: '#42CF9D' }}>投票</div>
    }
    return null
  }

  // 毫秒转换分秒
  formatDuring = (mss) => {
    let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = (mss % (1000 * 60)) / 1000;
    return minutes + " 分 " + seconds + " 秒 ";
  }

  /**
   * 延长收集时间
   */
  showDelay = () => {
    this.setState({
      delayVisible: true
    })
  }
  cancelDelay = () => {
    this.setState({
      delayVisible: false
    })
  }
  saveDelay = () => {
    const { libId, delayValue } = this.state
    getExtension({
      libId,
      date: delayValue
    }).then(res => {
      if (res && res.message === 'success') {
        this.mesSuccess('延长收集时间成功！')
        this.setState({
          delayVisible: false
        })
      } else {
        res && this.mesWarning(res.message)
      }
    })
  }
  changeDelayValue = (date, dateString) => {
    this.setState({
      delayValue: dateString
    })
  }

  /**
   * 确认无效原因
   */
  cancelInvalid = () => {
    this.setState({
      invalidVisible: false,
      invalidValue: ''
    })
  }
  saveInvalid = () => {
    const { invalidValue, selectAnswerId } = this.state
    getChangeInvalid({
      answerIdList: selectAnswerId,
      invalidReason: invalidValue
    }).then(res => {
      if (res && res.message === 'success') {
        this.setState({
          invalidVisible: false,
          invalidValue: ''
        }, () => {
          this.mesSuccess('已标记无效成功，您可以前往无效问卷列表中查看')
          this.requestTable()
        })
      } else {
        res && this.mesWarning(res.message)
      }
    })
  }
  changeInvalidValue = e => {
    this.setState({
      invalidValue: e.target.value
    })
  }

  // 查看无效问卷
  openInvalid = () => {
    this.props.history.push(`/invalid?libid=${_get_query('libid')}`)
    // window.open()
  }

  // 改变答卷收集状态
  changeCollectStatus = () => {
    const { collectStatus, libId } = this.state
    // collectStatus 1采集中 2暂停中
    // type 0暂停 1启动
    getChangeCollection({
      libId,
      type: collectStatus === 1 ? 0 : 1
    }).then(res => {
      if (res && res.message === 'success') {
        this.setState({
          collectStatus: collectStatus === 1 ? 2 : 1
        })
      }
    })
  }

  // 表格操作-查看
  openDetail = id => {
    window.sessionStorage.setItem("libId" , JSON.stringify(_get_query('libid')))
    this.props.history.push(`details?id=${id}`)
    // window.open('')
  }

  // 改变关联内容
  changeRelated = e => {
    this.setState({
      relatedSelect: e
    }, () => {
      this.requestTable()
    })
  }

  // 改变答卷状态
  changeQuestionStatus = e => {
    console.trace(e);
    this.setState({
      questionStatus: e
    }, () => {
      this.requestTable()
    })
  }


  // 改变答题开始时间
  changeStartTime = (date, dateString) => {
    this.setState({
      startTime: dateString
    }, () => {
      this.requestTable()
    })
  }

  // 确认正常
  confirm = id => {
    // 确认正常
    let answerId = new Array(id)
    getConfirmNormal({ answerIdList: answerId }).then(res => {
      if (res && res.message === 'success') {
        this.mesSuccess('修改成功，问卷已更新为正常状态')
        this.requestTable()
      } else {
        res && this.mesWarning(res.message)
      }
    })
  }

  // 标记无效
  signInvalid = id => {
    // 标记无效
    let answerId = new Array(id)
    this.setState({
      selectAnswerId: [...answerId],
      invalidVisible: true
    })
  }

  //选择行
  selectedRowChange = (selectedRowKeys, selectedRows) => {
    const { dataSource } = this.state
    this.setState({
      selectedRowKeyList: [...selectedRowKeys],
      selectedRowList: selectedRows,
      allSelectFlag: dataSource.length === selectedRowKeys.length
    })
  }

  // 改变pageNum
  pageChange = pageNum => {
    // 清空selectrowkeys selectrows
    this.setState({
      pageNum,
      selectedRowKeyList: [],
      selectedRowList: [],
      allSelectFlag: false,
    }, () => {
      this.requestTable()
    })
  }

  // 改变pageSize
  onShowSizeChange = (current, pageSize) => {
    // 清空selectrowkeys selectrows
    this.setState({
      pageSize,
      selectedRowKeyList: [],
      selectedRowList: [],
      allSelectFlag: false,
    }, () => {
      this.requestTable()
    })
  }

  // 全选
  allSelect = e => {
    const { dataSource, selectedRowKeyList, allSelectFlag } = this.state
    let list = dataSource.map(item => item.answerId)
    this.setState(() => {
      return {
        allSelectFlag: !allSelectFlag,
        selectedRowKeyList: e.target.checked ? [...list] : [],
        selectedRowList: e.target.checked ? [...dataSource] : [],
      }
    })
  }

  // 批量标记无效
  batchInvalid = () => {
    const { selectedRowList } = this.state
    if (selectedRowList.length < 2) {
      this.mesWarning('请勾选至少两个答卷')
    } else {
      // 点击批量标记无效时，用户在弹出窗口中键入无效原因。键入长度限制：10个字
      // 并toast提示：已标记无效成功，您可以前往无效问卷列表中查看。同时答卷从当前列表移除，用户可以在无效答卷中查看
      let list = selectedRowList.map(item => item.answerId)
      this.setState({
        invalidVisible: true,
        selectAnswerId: list
      })
    }
  }

  // 批量确认正常
  batchConfirm = () => {
    const { selectedRowList } = this.state
    let flag = true
    if (selectedRowList.length < 2) {
      this.mesWarning('请勾选至少两个答卷')
    } else {
      selectedRowList.forEach(item => {
        if (item.answerStatus === 0) {
          flag = false
        }
      })
    }
    if (flag) {
      // 修改
      let list = selectedRowList.map(item => item.answerId)
      selectedRowList.length > 1 && getConfirmNormal({ answerIdList: list }).then(res => {
        if (res && res.message === 'success') {
          this.mesSuccess('修改成功，问卷已更新为正常状态')
          this.requestTable()
        } else {
          res && this.mesSuccess(res.message)
        }
      })
    } else {
      this.mesSuccess('选中问卷中有正常状态的问卷!')
    }
  }
  handClick = () => {
    sessionStorage.removeItem("libId");
    this.props.history.push('/')
  }

  render() {
    const { title, researchType, lastUpdateTime, researchCount,
      pageNum, pageSize, selectedRowKeyList, allSelectFlag, columns, delayVisible, invalidVisible, collectStatus, invalidValue,
      relatedSelect, questionStatus, startTime, relatedList, dataSource, total } = this.state
    // const { dataSource, total } = this.props
    return (
      <div className="researchBody">
        <HeaderText handClick={() => this.handClick()} CenterText={'有效问卷列表'}></HeaderText>
        <div className="bodyContent">
          <div className="researchInfo">
            <div className="researchInfo_left">
              <div><img src={window.$$setImgSrc('QN_iconDh.png')}></img></div>
              <div className="researchInfo_titleDate">
                <div className="researchInfo_title">
                  <span className="infoTitle" title={title}>{title}</span>
                  {this.returnResearchType(researchType)}
                </div>
                <div className="researchInfo_date">
                  最后更新时间：{lastUpdateTime}
                </div>
              </div>
            </div>
            <div className="researchInfo_center">
              <div>
                <span className="count">{researchCount}</span>
                <span>份</span>
              </div>
              <div>当前已收集数据</div>
            </div>
            <div className="researchInfo_right">
              <Button type="primary" ghost onClick={this.openInvalid}>查看无效问卷</Button>
              <Button type="primary" ghost onClick={this.changeCollectStatus}>{collectStatus === 1 ? '暂停收集' : '启动收集'}</Button>
              <Button type="primary" onClick={this.showDelay}>延长收集时间</Button>
            </div>
          </div>
          <div className="tableCondition">
            <Col className="tc_left" span={7}>
              <span>关联内容</span>
              <Select defaultValue={relatedList.length !== 0 ? relatedList[0].linkId : ""} value={relatedSelect} onChange={this.changeRelated} style={{ width: 240 }}>
                {relatedList.length !== 0 && relatedList.map(item => <Option key={item.linkId} value={item.linkId}>{item.linkTitle}</Option>)}
              </Select>
            </Col>
            <Col className="tc_center" span={7}>
              <span>答卷状态</span>
              <Select defaultValue="" value={questionStatus} onChange={this.changeQuestionStatus} style={{ width: 240 }}>
                <Option value="">全部</Option>
                <Option value={0}>正常</Option>
                <Option value={1}>待确认</Option>
              </Select>
            </Col>
            <Col className="tc_right" span={8}>
              <span>答题开始时间</span>
              <DatePicker style={{ width: 240 }} onChange={this.changeStartTime} showTime={{ format: 'HH:mm:ss' }} placeholder="请选择开始时间" format="YYYY-MM-DD HH:mm:ss" />
            </Col>
          </div>
          <div className="table">
            <div className="tableCount">
              共{total}条记录
            </div>
            <div>
              <Table
                rowKey='answerId'
                dataSource={dataSource}
                columns={columns}
                rowSelection={
                  {
                    onChange: this.selectedRowChange,
                    selectedRowKeys: selectedRowKeyList,
                  }
                }
                pagination={{
                  showSizeChanger: true,
                  onChange: this.pageChange,
                  onShowSizeChange: this.onShowSizeChange,
                  current: pageNum,
                  pageSize,
                  total
                }}
                scroll={
                  {
                    x: false
                  }
                }
              />
            </div>
          </div>
        </div>
        <div className="footerContent">
          <Checkbox disabled={dataSource.length === 0} checked={allSelectFlag} onChange={this.allSelect}>全选</Checkbox>
          <Button type="primary" onClick={this.batchInvalid}>批量标记无效</Button>
          <Button type="primary" ghost onClick={this.batchConfirm}>批量确认正常</Button>
        </div>

        <Modal
          title='延长收集时间'
          visible={delayVisible}
          destroyOnClose={true}
          onOk={this.saveDelay}
          onCancel={this.cancelDelay}
        >
          结束时间 &nbsp;&nbsp; <DatePicker onChange={this.changeDelayValue} style={{ width: 240 }} showTime={{ format: 'HH:mm:ss' }} placeholder="请选择开始时间" format="YYYY-MM-DD HH:mm:ss" />
        </Modal>

        <Modal
        centered={true}
          title='确认无效'
          visible={invalidVisible}
          destroyOnClose={true}
          onOk={this.saveInvalid}
          onCancel={this.cancelInvalid}
        >
          <Input placeholder="请输入无效原因" autoComplete="off" maxLength={10} onChange={this.changeInvalidValue} />
          <div style={{ textAlign: 'right' }}>{invalidValue.length}/10</div>
        </Modal>
      </div>
    )
  }
}

export default ValidQuestionList
