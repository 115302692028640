import React, { Component, useState } from 'react';
import "./index.less"
import HeaderText from '../../components/headerText'
import { Select, Button, Input, Pagination, Menu, Modal, message,Breadcrumb } from 'antd';
import { historyQuestion, publish, publishQuestion, copyQuestion } from '../../api/Homeinterface/index'
import moment from 'moment'

const Preview = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 10.7133C9.63305 10.7133 11.1482 9.84097 12.5913 8.00518C11.1482 6.1611 9.63305 5.28671 8 5.28671C6.36695 5.28671 4.85184 6.15903 3.40868 7.99482C4.85184 9.8389 6.36695 10.7133 8 10.7133ZM8 12C5.89323 12 3.97635 10.8873 2.22937 8.67029C1.92354 8.27661 1.92354 7.72132 2.22937 7.32764C3.96635 5.11267 5.89323 4 8 4C10.1068 4 12.0217 5.11267 13.7706 7.32971C14.0765 7.72339 14.0765 8.27868 13.7706 8.67236C12.0217 10.8977 10.1068 12 8 12Z" fill="#888888" />
        <path d="M8.00536 8.50346C8.27851 8.50346 8.50346 8.27851 8.50346 8.00535C8.50346 7.7322 8.27851 7.50725 8.00536 7.50725C7.7322 7.50725 7.50725 7.7322 7.50725 8.00535C7.50725 8.27851 7.72328 8.50346 8.00536 8.50346ZM8.00536 9.78353C7.36621 9.78353 6.78241 9.44432 6.46284 8.89087C6.14327 8.33564 6.14327 7.65901 6.46284 7.10377C6.78241 6.54854 7.37514 6.21111 8.00536 6.21111C8.99264 6.21111 9.79246 7.01093 9.79246 7.99821C9.79246 8.98549 8.99264 9.78353 8.00536 9.78353ZM1.25686 14.5717L1.29078 12.1616C1.29078 11.8045 0.999777 11.5224 0.65164 11.5224C0.294577 11.5224 0.0124972 11.8134 0.0124972 12.1616V14.927C0.0124972 15.5197 0.492747 16 1.08547 16H3.85093C4.20799 16 4.49007 15.709 4.49007 15.3609C4.49007 15.0127 4.19906 14.7217 3.85093 14.7217L1.44968 14.7467C1.34791 14.7485 1.264 14.6717 1.25686 14.5717ZM1.41576 1.29078H3.83843C4.19549 1.29078 4.47757 0.999777 4.47757 0.65164C4.47757 0.294577 4.18657 0.0124973 3.83843 0.0124973H1.07297C0.48025 0.0124973 0 0.492747 0 1.08547V3.85093C0 4.20799 0.291006 4.49007 0.639143 4.49007C0.996206 4.49007 1.27829 4.19906 1.27829 3.85093V1.42647C1.28007 1.35148 1.34077 1.29078 1.41576 1.29078ZM14.711 1.38898V3.83843C14.711 4.21513 15.0341 4.50792 15.4055 4.47579C15.7376 4.44722 15.9893 4.16157 15.9893 3.82772V1.07297C15.9893 0.48025 15.509 0 14.9163 0H12.1526C11.7956 0 11.5135 0.291007 11.5135 0.639143C11.5135 0.996206 11.8045 1.27829 12.1526 1.27829H14.6021C14.6628 1.28007 14.711 1.33006 14.711 1.38898ZM14.5378 14.7128H12.1634C11.7867 14.7128 11.4939 15.0359 11.526 15.4073C11.5546 15.7393 11.8402 15.9911 12.1741 15.9911H14.927C15.5197 15.9911 16 15.5108 16 14.9181V12.1544C16 11.7777 15.6769 11.4849 15.3055 11.5171C14.9734 11.5456 14.7217 11.8313 14.7217 12.1651V14.5325C14.7217 14.6324 14.6414 14.7128 14.5414 14.7128H14.5378Z" fill="#888888" />
    </svg>
}
const Copy = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.317 2.3418C11.7827 2.3418 12.2294 2.52681 12.5587 2.85613C12.888 3.18546 13.073 3.63211 13.073 4.09785V14.2439C13.073 14.7097 12.888 15.1563 12.5587 15.4856C12.2294 15.815 11.7827 16 11.317 16H2.34162C1.87589 16 1.42923 15.815 1.09991 15.4856C0.770583 15.1563 0.585571 14.7097 0.585571 14.2439V4.09785C0.585571 3.63211 0.770583 3.18546 1.09991 2.85613C1.42923 2.52681 1.87589 2.3418 2.34162 2.3418H11.317ZM11.317 3.5125H2.34162C2.26475 3.5125 2.18864 3.52764 2.11762 3.55705C2.0466 3.58647 1.98207 3.62959 1.92772 3.68394C1.87336 3.7383 1.83025 3.80283 1.80083 3.87384C1.77141 3.94486 1.75627 4.02098 1.75627 4.09785V14.2439C1.75627 14.3992 1.81794 14.548 1.92772 14.6578C2.03749 14.7676 2.18638 14.8293 2.34162 14.8293H11.317C11.4722 14.8293 11.6211 14.7676 11.7309 14.6578C11.8407 14.548 11.9023 14.3992 11.9023 14.2439V4.09785C11.9023 3.9426 11.8407 3.79372 11.7309 3.68394C11.6211 3.57417 11.4722 3.5125 11.317 3.5125Z" fill="#888888" />
        <path d="M4.09768 5.26855H9.56095C9.95118 5.26855 10.1463 5.46367 10.1463 5.8539C10.1463 6.24414 9.95118 6.43925 9.56095 6.43925H4.09768C3.70745 6.43925 3.51233 6.24414 3.51233 5.8539C3.51233 5.46367 3.70745 5.26855 4.09768 5.26855Z" fill="#888888" />
        <path d="M4.09768 8H9.56095C9.95118 8 10.1463 8.19512 10.1463 8.58535C10.1463 8.97558 9.95118 9.1707 9.56095 9.1707H4.09768C3.70745 9.1707 3.51233 8.97558 3.51233 8.58535C3.51233 8.19512 3.70745 8 4.09768 8Z" fill="#888888" />
        <path d="M4.09768 10.7305H7.21954C7.60978 10.7305 7.80489 10.9256 7.80489 11.3158C7.80489 11.7061 7.60978 11.9012 7.21954 11.9012H4.09768C3.70745 11.9012 3.51233 11.7061 3.51233 11.3158C3.51233 10.9256 3.70745 10.7305 4.09768 10.7305Z" fill="#888888" />
        <path d="M4.29269 1.1707H13.6583C13.8135 1.1707 13.9624 1.23237 14.0722 1.34215C14.182 1.45192 14.2436 1.60081 14.2436 1.75605V12.2924C14.2436 12.4476 14.3053 12.5965 14.4151 12.7063C14.5249 12.816 14.6737 12.8777 14.829 12.8777C14.9842 12.8777 15.1331 12.816 15.2429 12.7063C15.3527 12.5965 15.4143 12.4476 15.4143 12.2924V1.75605C15.4143 1.29032 15.2293 0.843659 14.9 0.514335C14.5707 0.185012 14.124 0 13.6583 0H4.29269C4.13744 0 3.98856 0.0616708 3.87878 0.171445C3.76901 0.28122 3.70734 0.430106 3.70734 0.58535C3.70734 0.740595 3.76901 0.889481 3.87878 0.999255C3.98856 1.10903 4.13744 1.1707 4.29269 1.1707Z" fill="#888888" />
    </svg>
}

function _get_query(name) {
    const search = window.location.href
    if (!search) return null
    const item = search.split('&').find(v => {
        return v.includes(name)
    });
    if (item) {
        const _item_arr = item.split('=')
        return _item_arr[1]
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}

const { Option } = Select;

//调研类型 
const arrData = [{
    text: '全部调研', id: ''
}, {
    text: '问卷', id: '0'
}, {
    text: '反馈', id: '1'
},
{
    text: '投票', id: '2'
}]

class index extends Component {
    state = {
        curbuttonid: '',
        // 下拉菜单
        visible: false,
        // 发布弹框
        virelease: false,
        // 结束弹框
        endvisible: false,
        // 列表
        dataList: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
        // 搜索名称
        Inputbox: '',

        //当前的 _libId
        _libId: _get_query('id')
    }
    // 发布弹框
    showModal = () => {
        this.setState({
            virelease: true,
        });

    };

    hideModal = (libId) => {
        const { _libId } = this.state
        publishQuestion({ libId: _libId }).then((res) => {
            this.setState(() => {
                return {
                    libId: _libId
                }
            })

        })

    };

    confirm = (e) => {
        message.success('Click on Yes');
    }

    cancel = (e) => {
        message.error('Click on No');
    }
    // 复制按钮
    historyCopy = async (v) => {
        const res = await copyQuestion({ libId: v.libId })
        if (res) {
            sessionStorage.setItem('router', JSON.stringify({ result: res.result.libId, questionTitle: res.result.questionTitle }))
            this.props.history.push(`/editQuestion/editContent?libid=${res.result.libId}&title=${res.result.questionTitle}&edit=${~~((Math.random() + 1) * 10)}&type=1&oldid=${v.libId}&wjType=${v.type}`)
        } else {
            this.mesWarning("数据错误")
        }
    }
    // 预览按钮
    historyPreview = (v) => {
        // message.info("跳转预览页面，开发中……",0.5)
        window.open(`/previewQuestion?libid=${v.libId}&type=1&wjType=${v.type}`)
    }


    handleMenuClick = (e, v) => {
        this.setState(() => {
            return {
                visible: false
            }
        })
        if (e.key === '1') {
            this._preview(v)
        }
    };
    handleVisibleChange = flag => {


        this.setState(() => {
            return { visible: flag }
        });
    };
    // 状态
    onChangestatus = (item) => {
        console.log(`selected ${item}`);
        this.getDataList({
            status: item,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
    }


    handClick = () => {
        this.props.history.push('/')
    }
    //新建调研
    build = () => {
        this.props.history.push('/create')
    }

    componentDidMount() {
        this.getDataList({
            pageSize: this.state.pageSize,
            pageNum: this.state.pageNum
        })
    }


    //  列表
    getDataList = (par) => {
        return historyQuestion(par).then(res => {

            if (res.code === 0) {
                this.setState(() => {
                    return {
                        dataList: res.result.data,
                        total: res.result.total
                    }
                })

            } else {
                this.mesWarning("数据错误")
            }


        })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
        }, () => {
            this.getDataList({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };
    //输入框
    onInputbox = () => {
        const { Inputbox } = this.state;
        this.getDataList({
            questionTitle: Inputbox,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
    }
    // 类型
    getselect = (item) => {
        this.setState(() => {
            return {
                curbuttonid: item.id,
            }
        })
        this.getDataList({
            type: item.id,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
    }
    _preview = (v) => {
        this.props.history.push(`/previewQuestion?libid=${v.libId}`)
    }

    render() {
        const { dataList, total } = this.state
        return (
            <div className="main_box">
                <HeaderText handClick={() => this.handClick()} CenterText={'历史调研'}></HeaderText>
                <div>
                    <Breadcrumb className="title_bread">
                        <Breadcrumb.Item>
                            <a href="">学习工具</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="">调研工具</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="">历史模板</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/* mian */}
                <div className="main_create">
                    {/* main_top */}
                    <div className="m_buttom1">
                        <div>
                            {
                                arrData.map((item, index) => {
                                    return <span key={index} onClick={() => {
                                        this.getselect(item)
                                    }}
                                        className={this.state.curbuttonid === item.id ? 'blueBtn' : ""} > {item.text}</span>
                                })
                            }
                        </div>
                    </div>
                    <div className="search_ipt">
                        <div>
                            <div id="c_input">
                                <Input
                                    className="in"
                                    prefix={<img src={window.$$setImgSrc('QN_searchIcon.png')} />}
                                    placeholder="请输入调研名称"
                                    style={{ width: 230 }}
                                    value={this.state.Inputbox}
                                    onChange={value => {
                                        this.setState({
                                            Inputbox: value.target.value
                                        })
                                    }}
                                    onPressEnter={v => {
                                        this.onInputbox()
                                    }}
                                />
                                <span className="c_span">共计{total}个调研项目</span>
                            </div>
                        </div>
                    </div>
                    <div className="main_border">
                        <div className="content_border">
                            {
                                dataList.map((v, i) => {

                                    return <div className='h_item' key={i}>
                                        <div>
                                            {
                                                v.type === '0' ? <img src={window.$$setImgSrc('QN_iconBlue.png')}></img> :
                                                    v.type === '1' ? <img src={window.$$setImgSrc('QN_iconOrigin.png')}></img> :
                                                        v.type === '2' ? <img src={window.$$setImgSrc('QN_iconGreen.png')}></img> : ''
                                            }
                                            <span
                                            >{v.type === '0' ? "问卷" : v.type === '1' ? "反馈" : v.type === '2' ? "投票" : ''}</span>
                                        </div>

                                        <div>
                                            <div>
                                                <div>
                                                    <h3>{v.questionTitle ? v.questionTitle.length > 15 ? `${v.questionTitle.substr(0, 15)}...` : v.questionTitle : ""}</h3>


                                                </div>
                                            </div>
                                            {/*  */}
                                            <div>
                                                {/* 问卷导语： */}
                                                {v.introduction ? v.introduction.length > 60 ? `${v.introduction.substr(0, 60)}...` : `问卷导语：${v.introduction}` : <>暂无导语 <br /></>}
                                            </div>
                                        </div>
                                        {/*  */}
                                        <div className="bottom_foo">
                                            <div className="pp" >
                                                <span>{v.questionNum || 0}道问题</span>
                                            </div>
                                            <div className="c_fonts">
                                                <span className="template_foo" onClick={() => this.historyCopy(v)}>
                                                    <em>
                                                        <Copy />
                                                    </em>
                                                    &nbsp;<i>复制</i>
                                                </span>
                                                <span onClick={() => this.historyPreview(v)} className="template_foo" style={{ marginLeft: 20 }}>
                                                    <em>
                                                        <Preview />
                                                    </em>
                                                    &nbsp;<i>预览</i>
                                                </span>
                                            </div>
                                        </div>


                                    </div>
                                })
                            }
                            <div style={{
                                display: 'flex', justifyContent: "flex-end", width: "100%"
                            }}>
                                <Pagination
                                    dataSource={this.state.dataList} defaultCurrent={1}
                                    pageSizeOptions={['10', '20', '30']}
                                    onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)}
                                    onChange={(a, b) => this.onPageNumChange(a, b)}
                                    showSizeChanger={true}
                                    current={this.state.pageNum}
                                    pageSize={this.state.pageSize}
                                    total={this.state.total}
                                />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        );
    }
}

export default index;




















