
import { applyMiddleware, createStore, combineReducers } from 'redux'
import thunkMiddleware  from 'redux-thunk'

let moduleFile = require.context('./WEN_JUAN', true, /\index.js$/);
let res = moduleFile.keys().reduce((prev, item) => {
    let son = moduleFile(item).default;
    let name = item.split('/')[1].toLocaleUpperCase();
    prev = Object.assign({}, prev, { [name]: son })
    return prev
}, {});


const reducer = combineReducers({ ...res })
const store = createStore(reducer, applyMiddleware(thunkMiddleware))
export default store