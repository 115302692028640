
export let baseURL = 'https://gw.cveducloud.com';
export let domain = ''; // 主域名
export let loginUrl = ''; //登录中心

export const setBaseUrl = (url) => {
    baseURL = url;
};

export const setDomain = (url) => {
    domain = url;
};

export const setLoginUrl = (url) => {
    loginUrl = url;
};
// 依赖07接口
export const initSecretUrlByType07 = (config) => {
    setBaseUrl(config?.gw ?? '');
    let domain = config?.gw
        ?.split('.')
        .reverse()
        .slice(0, 2)
        .reverse()
        .join('.');
    setDomain(domain ? `.${domain}` : '');
    setLoginUrl(config?.sys?.logincenter ?? '');
};

















// const REACT_APP_BUILD_URL = process.env.REACT_APP_BUILD_URL;
// const urlObj = {};

// if (REACT_APP_BUILD_URL && REACT_APP_BUILD_URL.includes('pro')) {

//     urlObj.baseURL = "";
//     urlObj.loginUrl = ''
// }  else {
//     urlObj.baseURL = '';
//     // urlObj.loginUrl = ''
//     urlObj.loginUrl = '';
//     // urlObj.baseURL = '';
// }

// export const baseURL = urlObj.baseURL
// export const loginUrl = urlObj.loginUrl


