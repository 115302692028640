import React, { Component } from 'react'
import { Button, message } from 'antd'
import './index.less'

const LeftIcon = () => {
    return <>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00642 4.01977H1.73841L5.61202 0.91489C5.66958 0.876625 5.71858 0.826822 5.75589 0.768635C5.7932 0.710448 5.81802 0.645146 5.82878 0.576865C5.83954 0.508585 5.836 0.438814 5.81838 0.371973C5.80077 0.305132 5.76947 0.242678 5.72645 0.188567C5.68344 0.134456 5.62966 0.0898674 5.56852 0.0576263C5.50738 0.0253852 5.4402 0.00619414 5.37126 0.00126885C5.30231 -0.00365644 5.23309 0.0057914 5.16798 0.0290139C5.10288 0.0522363 5.04331 0.0887272 4.99304 0.136175L0.00127564 4.12959V4.30929C-0.000425212 4.33255 -0.000425212 4.35591 0.00127564 4.37917V4.42909C-0.000425212 4.45236 -0.000425212 4.47571 0.00127564 4.49898V4.67868L4.99304 8.67209C5.04417 8.71339 5.10294 8.74421 5.16598 8.7628C5.22902 8.78139 5.29511 8.78737 5.36047 8.78042C5.42582 8.77347 5.48917 8.75371 5.54689 8.72228C5.60462 8.69084 5.65558 8.64835 5.69688 8.59721C5.73818 8.54608 5.769 8.48732 5.78759 8.42428C5.80617 8.36123 5.81216 8.29515 5.80521 8.22979C5.79826 8.16443 5.7785 8.10108 5.74706 8.04336C5.71563 7.98564 5.67313 7.93467 5.622 7.89338L1.73841 5.01812H9.00642C12.1912 5.01812 14.7869 7.25443 14.7869 10.0099C14.7869 12.7653 12.1912 15.0016 9.00642 15.0016H1.30912C1.17673 15.0016 1.04976 15.0542 0.956147 15.1479C0.862533 15.2415 0.809942 15.3684 0.809942 15.5008C0.809942 15.6332 0.862533 15.7602 0.956147 15.8538C1.04976 15.9474 1.17673 16 1.30912 16H9.00642C12.7902 16 15.7852 13.3144 15.7852 10.0099C15.7852 6.70534 12.7902 4.01977 9.00642 4.01977Z" fill="#333333" />
        </svg>
        <span> &nbsp;
        返回
         </span>
    </>

}
const Eye = () => {
    return <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99077 13.2933C4.81477 13.2933 2.21012 10.4606 1.23821 9.2427C0.888695 8.80247 0.888695 8.18947 1.23821 7.75033C2.21012 6.53261 4.81368 3.69995 7.99077 3.69995C11.1679 3.69995 13.7718 6.53261 14.7433 7.75033C15.0928 8.18947 15.0928 8.80247 14.7433 9.24214C13.7718 10.4606 11.1668 13.2933 7.99077 13.2933ZM7.99077 4.89433C5.25723 4.89433 2.84641 7.65065 2.17266 8.49586C2.84641 9.34256 5.2587 12.0989 7.99077 12.0989C10.7232 12.0989 13.1347 9.34256 13.8093 8.49751L13.8089 8.49586C13.1348 7.65067 10.7243 4.89433 7.99077 4.89433Z" fill="#3E78ED" />
        <path d="M7.99083 10.9042C6.67344 10.9042 5.60205 9.83263 5.60205 8.51524C5.60205 7.19785 6.67344 6.12646 7.99083 6.12646C8.26327 6.12646 8.52983 6.172 8.78538 6.26178C9.09673 6.3719 9.25938 6.71318 9.14888 7.024C9.03948 7.33482 8.6991 7.49581 8.38776 7.38857C8.26071 7.3436 8.12743 7.32085 7.99083 7.32085C7.33177 7.32085 6.79643 7.8567 6.79643 8.51523C6.79643 9.17376 7.33179 9.70963 7.99083 9.70963C8.64988 9.70963 9.18524 9.17376 9.18524 8.51523C9.18524 8.18515 9.45252 7.91803 9.78223 7.91803C10.1127 7.91803 10.38 8.18515 10.38 8.51523C10.38 9.83263 9.30823 10.9042 7.99083 10.9042Z" fill="#3E78ED" />
    </svg>

}
export default class headerAction extends Component {
    state = {
        RouterTab: [{
            name: "编辑内容",
            path: `/editQuestion/editContent?libid=${JSON.parse(sessionStorage.getItem("router")).result}&title=${JSON.parse(sessionStorage.getItem("router")).questionTitle}`
        }, {
            name: "高级设置",
            path: "/editQuestion/heightSet"
        }
        // , {
        //     name: "模板设置",
        //     path: `/editQuestion/moduleSet?libid=${JSON.parse(sessionStorage.getItem("router")).result}`,
        // }
        ],
        currentTanIndex: Number(sessionStorage.currentTanIndex) || 0
    }
    go = (v, i) => {
        this.props.history.push(v.path)
        this.setState(() => {
            window.sessionStorage.currentTanIndex = i
            return {
                currentTanIndex: i
            }
        })

    }
    componentWillUnmount() {
        window.sessionStorage.currentTanIndex = null
    }
    render() {
        const { CenterText, handClick } = this.props
        let { RouterTab, currentTanIndex } = this.state
        return (
            <div className='headerAction'>
                <div className="headerActionleft" onClick={handClick}>
                    <LeftIcon />
                </div>
                <div className="headerActionright">
                    <div className="uul">
                        {RouterTab.map((v, i) => {
                            return <div key={v.path} className={`item ${currentTanIndex === i ? 'active' : ''}`} onClick={() => this.go(v, i)}>
                                {v.name}
                            </div>
                        })}
                    </div>
                    <div className="rrl">
                        <div className="item" onClick={this.props.Save}>
                            <Button type='primary' className="save">发布</Button>
                        </div>
                        <div className="item" onClick={this.props._prop_preview}>
                            <Button  className="pre">预览</Button>
                        </div>
                        <div className="item" onClick={this.props.Save_not_pub}>
                            <Button  className="saveNot">保存不发布</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
