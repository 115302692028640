import React, { Component } from 'react'
import './index.less'
import HeaderText from '../../components/headerText'

const Hook = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#2E69E0" />
        <path d="M10.8207 14.1839C11.0563 13.9706 11.2422 13.8096 11.4188 13.6395C13.1097 12.0115 14.8032 10.3847 16.4875 8.75147C16.8958 8.35624 17.3224 8.15732 17.8224 8.57087C18.2412 8.91637 18.1783 9.39797 17.6575 9.90182C15.6617 11.8335 13.6593 13.7638 11.6557 15.6902C11.0275 16.2935 10.6859 16.2975 10.0695 15.7099C8.982 14.6707 7.90624 13.6185 6.81739 12.5807C6.40646 12.1894 6.19837 11.7772 6.62632 11.2943C6.98491 10.8899 7.48484 10.9488 8.00701 11.45C8.94012 12.3465 9.86408 13.2534 10.8207 14.1839Z" fill="white" />
    </svg>
}
const Text = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9453 0.411133L15.4453 4.91113V14.6611C15.4453 15.1111 15.1453 15.4111 14.6953 15.4111H11.6953C11.2453 15.4111 10.9453 15.1111 10.9453 14.6611C10.9453 14.2111 11.2453 13.9111 11.6953 13.9111H13.9453V5.66113H10.9453C10.4953 5.66113 10.1953 5.36113 10.1953 4.91113V1.91113H4.94531V8.66113C4.94531 9.11113 4.64531 9.41113 4.19531 9.41113C3.74531 9.41113 3.44531 9.11113 3.44531 8.66113V1.16113C3.44531 0.711133 3.74531 0.411133 4.19531 0.411133H10.9453Z" fill="white" />
        <path d="M8.39531 9.56175L10.7203 11.8868L10.7953 11.9618C10.8703 12.1118 10.9453 12.2618 10.9453 12.4118C10.9453 12.5618 10.8703 12.7118 10.7953 12.8618L10.7203 12.9368L8.47031 15.1868L8.39531 15.2618C8.09531 15.4868 7.72031 15.4868 7.49531 15.2618L7.42031 15.1868L7.34531 15.1118C7.12031 14.8118 7.12031 14.4368 7.34531 14.2118L7.42031 14.1368L8.39531 13.1618H4.12031C3.74531 13.0868 3.44531 12.7868 3.44531 12.4118C3.44531 12.0368 3.74531 11.7368 4.12031 11.6618H8.39531L7.42031 10.6868C7.12031 10.3868 7.12031 10.0118 7.34531 9.71175L7.42031 9.63675C7.72031 9.33675 8.09531 9.33675 8.39531 9.56175ZM1.94531 11.6618C2.39531 11.6618 2.69531 11.9618 2.69531 12.4118C2.69531 12.7868 2.39531 13.0868 2.02031 13.1618H1.19531C0.745312 13.1618 0.445312 12.8618 0.445312 12.4118C0.445312 12.0368 0.745313 11.7368 1.12031 11.6618H1.94531Z" fill="white" />
    </svg>
}

export default class index extends Component {
    handClick = () => {
        this.props.history.push('/main')
    }
    back = () => {
        // this.props.history.push('/main')
        window.open("about:blank","_self")
        window.close();
    }
    render() {
        return (
            <div className="s_main_body">
                {/*<HeaderText handClick={() => this.handClick()} CenterText={'答题详情'}></HeaderText>*/}
                <div className='HeaderText'>
                    <div className="HeaderTextright">答题详情</div>
                </div>
                <div className="s_content">
                    <div className="s_img" style={{ background: `url(${window.$$setImgSrc('QN_submitSuccess.jpg')})` }}></div>
                    <div className="s_sic">
                        <Hook />
                        <span>提交成功</span>
                    </div>
                    <p>感谢您能够完成本次问卷</p>
                    <div className="s_btn" onClick={() => this.back()}>
                        <Text />
                        <span>返回列表</span>
                    </div>
                </div>
            </div>
        )
    }
}
