import React, { Component } from 'react'
import './index.less'
import HeaderText from '../../components/headerText'
import { Select, DatePicker, Space, Table, Button, Radio, Tooltip, Checkbox, message } from 'antd';
import { InValidQuestionList, relatedContent, batchValid, answerHeader } from '../../../src/api/Homeinterface/index.js'
import moment from 'moment'
const { Option } = Select;
const _linkid_const = 'xxxxxxxxxxxxxxxxxxxxxxxxxx'
function _get_query(name) {
    const search = window.location.href

    if (!search) return null
    const item = search.split('?').find(v => {
        return v.includes(name)
    });
    if (item) {
        const _item_arr = item.split('=')
        return _item_arr[1]
    } else {
        console.log(`${name} is not in search`)
        return null
    }
}
// console.log(_get_query('id'))
class index extends Component {
    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        pageNum: 1,
        pageSize: 10,
        total: 0,
        dataSource: [],
        // select
        dataSelect: [],
        // 筛选时间
        publishTime: null,
        dataHeader: [],
        //当前的 _libId
        _libId: _get_query('id')
    };
    DataonChange = (item) => {
        const o = {
            related: item,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }
        if (item === _linkid_const) {
            delete o.related
        }
        this.getData(o)
    }

    onBlur = () => {
        console.log('blur');
    }

    onFocus = () => {
        console.log('focus');
    }

    onSearch = (val) => {
        console.log('search:', val);
    }
    // 时间
    onChange = (value, dateString) => {
        if (dateString === '') {
            this.getData({
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize
            })
        } else {
            this.setState(() => {
                return {
                    publishTime: dateString
                }

            })
        }

    }



    onOk = (value) => {
        this.setState(() => {
            return {
                publishTime: moment(value._d).format('YYYY-MM-DD HH:mm:ss')
            }
        }, () => {
            const { publishTime } = this.state
            this.getData({
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
                startTime: publishTime
            })
        })
    }
    //   table
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };
    // 底部按钮
    CheckonChange = (e) => {
        const checked = e.target.checked
        // const {dataSource}=this.state
        this.setState(() => {
            return {
                selectedRowKeys: checked ? this.state.dataSource.map(v => v.answerId) : []
            }
        })
    }
    componentDidMount() {
        this.getData({
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        })
        this.getList()
        this.getheser()

    }
    // 列表筛选
    getList = (par) => {
        return relatedContent(par).then(res => {
            if (res) {
                res.result.unshift({
                    linkId: _linkid_const,
                    linkTitle: "全部"
                })
                // debugger
                this.setState(() => {
                    return {
                        dataSelect: res.result
                    }
                })
            } else {
                this.mesWarning('数据错误')
            }

        })
    }
    // header
    getheser = (par, _libId) => {
        return answerHeader({
            libId: _get_query('libid'), ...par


        }).then(res => {
            if (res.code === 0) {
                this.setState(() => {
                    return {
                        dataHeader: res.result
                    }
                })
            } else {
                this.mesWarning('数据错误')
            }
        })
    }

    //  列表
    getData = (par, libId) => {
        return InValidQuestionList({
            libId: _get_query('libid'),
            ...par
        }).then(res => {
            if (res.code === 0) {
                this.setState(() => {
                    return {
                        dataSource: res.result.data,
                        total: res.result.total
                    }
                })
            } else {
                this.mesWarning('数据错误')
            }

        })
    }

    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.getData({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };

    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
        }, () => {
            this.getData({
                pageSize: this.state.pageSize,
                pageNum: this.state.pageNum
            });
        }
        );
    };
    chehui = (a, b) => {

    }
    handClick = () => {

        this.props.history.push(`/valid?libid=${_get_query('libid')}`)
    }
    render() {
        const { loading, selectedRowKeys, dataSource, dataSelect, dataHeader, total } = this.state;
        // console.log(dataHeader, total, 'hhhh')
        const columns = [
            {
                title: '序号',
                dataIndex: 'name',
                render: (v, k, i) => <span>{i + 1}</span>,
                align: "center",
            },
            {
                title: '答卷ID',
                dataIndex: 'answerId',
                align: "center",
            },
            {
                title: '答题状态',
                dataIndex: 'answerStatus',
                render: (v, b, value) => <div className='action vertical_j' style={{ paddingLeft: "10px" }}>
                    <span
                    >
                        {b.answerStatus === 2 ? (
                            <span>无效</span>
                        ) : ''}
                    </span>

                </div>,
                align: "center",
            },
            {
                title: '开始时间',
                dataIndex: 'createTime',
                render: (text) => {


                    return <div>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</div>
                },
                align: "center",
            },
            {
                title: '关联内容',
                dataIndex: 'linkObj',
                align: "center",
                render: (text) => {


                    return <div>{text||'--'}</div>
                },
            },
            {
                title: '答题者',
                dataIndex: 'userName',
                align: "center",
                render: (text) => {


                    return <div>{text||'--'}</div>
                },
            },
            {
                title: '无效原因',
                dataIndex: 'validReason',
                align: "center",
                // render: (v, b, value) => <div className='action vertical_j' >
                //     <Tooltip placement="bottomRight" title={text} overlayClassName="invaild_color">
                //         <span>11ffffffffffff1</span>
                //     </Tooltip>
                // </div>validReason
            },
            {
                title: '操作',
                render: (v, b, value) => {
                    // console.log(v, b, 'vvvbbb')

                    return <div className='action vertical_j' style={{ cursor: 'pointer', display: "flex" }}>
                        <span
                            style={{ color: "#3E78ED", align: "center", cursor: 'pointer', marginLeft: 10 }}
                            onClick={async () => {
                                await batchValid({
                                    answerIdList: [b.answerId]
                                })
                                await this.getData({
                                    pageSize: this.state.pageSize,
                                    pageNum: this.state.pageNum
                                })
                            }

                            }
                        >恢复有效</span>
                        <span style={{ marginLeft: 7, color: '#eee' }}>|</span>
                        <span
                            style={{ color: "#3E78ED", align: "center", cursor: 'pointer', margin: '0px 0px 0px 11px' }}

                            onClick={() => {
                                window.sessionStorage.setItem("libId" , JSON.stringify(_get_query('libid')))
                                this.props.history.push(`/details?id=${v.answerId}`)
                                // console.log(v,b,value,'vavavvva')
                                
                            }}
                        >查看</span>
                    </div>
                },
                align: "center",
            },
        ];
        // const data = [];
        // for (let i = 0; i < selectedRowKeys; i++) {
        //     console.log(selectedRowKeys, 'eeeeesssssssssssss')
        //     console.log(selectedRowKeys, 'kes')
        //     data.push({
        //         key: i,
        //         name: `斌斌 ${i}`,
        //         age: 32,
        //         address: `憨憨 ${i}`,
        //     });
        // }
        // 文字提示 
        const text = <span>❤❤❤</span>;
        const buttonWidth = 70
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        // console.log(rowSelection, 'row')
        const hasSelected = selectedRowKeys.length > 0;

        return (
            <div className="m_main_invaild">
                <HeaderText handClick={() => this.handClick()} CenterText={'无效问卷列表'}></HeaderText>
                <div className="m_create_invay">
                    <div className="m_create_top">
                        <div>
                            <div>
                                <img src={window.$$setImgSrc('QN_iconDh.png')}></img>
                            </div>
                            <div>
                                <h3>问卷名称:{dataHeader.questionTitle ? dataHeader.questionTitle.length > 15 ? `${dataHeader.questionTitle.substr(0, 15)}...` : dataHeader.questionTitle : ""}</h3>

                                <div>
                                    最后更新时间：{moment(dataHeader.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                            </div>

                        </div>
                        <div>
                            <div><span>{total}</span>份</div>
                            <div>无效问卷数量</div>
                        </div>

                    </div>
                    <div className="m_create_buttom">
                        <div>
                            <div>
                                <span>关联内容</span>
                                <span>
                                    <Select
                                        showSearch
                                        style={{ width: 260 }}
                                        placeholder="全部"
                                        optionFilterProp="children"
                                        onChange={this.DataonChange}
                                        onFocus={this.onFocus}
                                        onBlur={this.onBlur}
                                        onSearch={this.onSearch}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            dataSelect.map((item, index) => {
                                                return <Option key={item.linkId}>
                                                    {item.linkTitle}
                                                </Option>
                                            })

                                        }
                                    </Select>
                                </span>
                            </div>
                            <div>
                                <span>开始时间</span>
                                <span>
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            style={{ width: 260 }}
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            onChange={this.onChange}
                                            onOk={this.onOk}


                                        />

                                    </Space>
                                </span>
                            </div>
                        </div>
                        {/*  */}
                        <div>
                            <Table rowSelection={rowSelection} rowKey={"answerId"} columns={columns} dataSource={dataSource}
                                pagination={
                                    {
                                        defaultCurrent: 1,
                                        pageSizeOptions: ['10', '20', '30'],
                                        showSizeChanger: true,
                                        onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                        onChange: (a, b) => this.onPageNumChange(a, b),
                                        current: this.state.pageNum,
                                        pageSize: this.state.pageSize,
                                        total: this.state.total,
                                    }
                                }
                            />
                        </div>

                    </div>
                    <div style={{
                        position: 'fixed',
                        bottom: 0,
                        background: '#fff',
                        width: '100vw',

                        left: 0
                    }}>
                        <div className="invaild_footer">
                            <div className="footer_button">
                                <div>
                                    <span>
                                        <Checkbox onChange={this.CheckonChange} checked={selectedRowKeys.length === this.state.dataSource.length && this.state.dataSource.length !== 0}>全选</Checkbox>
                                    </span>

                                </div>
                                <div>
                                    <Button type="primary" className="blue_btn"
                                        onClick={async () => {
                                            const { selectedRowKeys } = this.state
                                            if (!selectedRowKeys.length) return

                                            await batchValid({
                                                // answerId
                                                answerIdList: [...selectedRowKeys]


                                            })
                                            await this.getData({
                                                pageSize: this.state.pageSize,
                                                pageNum: this.state.pageNum
                                            })
                                        }

                                        }
                                    >批量恢复有效</Button>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

export default index
