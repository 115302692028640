import axios from "axios"
import { setToken } from "./token";
import md5 from "js-md5";
import { isEmpty } from './isEmpty';
import Cookies from 'js-cookie';
import { limitAnswerCount } from './extendHeader.js'

import { baseURL, loginUrl } from '../config/secret.js';

const Http = axios.create({
    timeout: 15000,
    withCredentials: false,
    baseURL
})
let headerData = '';
Http.interceptors.request.use((config) => {
    if (config.data && config.data.isBlob || config.params && config.params.isBlob) {
        config.responseType = 'arraybuffer'
    }
    let now = new Date().getTime();
    let info = Cookies.get('info'),
        token = null;
    if (info) {
        token = JSON.parse(info).token;
    }
    // token ="eyJhbGciOiJIUzUxMiJ9.eyJMT0dJTl9UWVBFIjoiUEMiLCJpZGVudGl0eU5hbWUiOiLogIHluIgiLCJ1c2VyX2lkIjoiM2EyNzRkNWIwNWQwNGM2ODg5MGIxMzI2MzdjODAzNDgiLCJpZGVudGl0eUlkIjoiNzIiLCJvcmduaXphdGlvbnMiOiI1YmM5ZWVmNDExODU0MjFkYTdjOTE3YzQyYjZjOTA5NyIsIlVTRVJfTkFNRSI6IjE4ODg4ODg4ODgzIiwibG9naW5fdXNlcl9rZXkiOiI4ZGQwOGVkYy1hNzM1LTQ1YmUtYmQ5My1kODkzNTAyZTI3NGUifQ.2Nu_kcAENUTPeWOrSXRPE1QqrFWXLTytpZNp0_cHi66ZMcLCkn1tUfmhZ___PiNUDPQY5NYwNh7FwSx0V9-j9w"

    config.headers = {
        ...config.headers,
        'token': token,
        "z-ts": now,
        "z-sign": md5(now + "")
    }
    let obj = limitAnswerCount(config.url);
    if (obj) {
        config.headers = {
            ...config.headers,
            ...obj
        }
    }
    return config
},
    err => {
        Promise.reject(err)
    }
)

Http.interceptors.response.use(

    response => {


        const { code } = response.data
        if (code && code !== 0) {
            Promise.reject(response.data);
        }



        let token = response.headers["token"]

        if (!isEmpty(token)) {
            setToken(token)
        }
        if (response.headers["content-disposition"]) {
            // debugger
            return response
        }
        return response.data
    },
    err => {
        Promise.reject(err)
    }
)
export const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        Http.get(url, { params })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
export const post = (url, params = {}, headerData) => {
    headerData = headerData;
    return new Promise((resolve, reject) => {
        Http.post(url, params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}



export default Http
