import { post } from "../../until/axios_instans";

// 限制答题次数
export function restrictNum(data) {
  return post("/api/wenjuan/api/v1/setting/cookieOrIPSet", data);
}

// 开始结束时间限制

export function timeastrict(data) {
    return post("/api/wenjuan/api/v1/setting/timeSet", data);
}
  // 参与限制接口
  export function participationConstraint(data) {
    return post("/api/wenjuan/api/v1/setting/range", data);
}
//答题匿名限制
export function anonymousLimit(data) {
  return post("/api/wenjuan/api/v1/setting/anonymous", data);
}



//答题质量控制
export function quality(data) {
  return post("/api/wenjuan/api/v1/setting/qualityControl", data);
}

//全局作答次数限制
export function answerNumber(data) {
  return post("/api/wenjuan/api/v1//setting/timeLong", data);
}

//获取所有高级设置
export function getAllSetting(data) {
  return post("/api/wenjuan/api/v1/setting/getAllSetting", data);
}
