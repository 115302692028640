import React, { Component } from 'react';
// import HeaderAction from '../../components/headerAction'
import './index.less'
import RouterView from '../../router/router_view'
class EditQuestion extends Component {
  
    render() {

        const { routers } = this.props
        return (
            <div className='EditQuestion'>
               
                <div  className="EditQuestion_content">
                    <RouterView ref={this.el} routers={routers}></RouterView>
                </div>
            </div>
        );
    }
}

export default EditQuestion;
