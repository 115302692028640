import { post } from "../../until/axios_instans";

//来源渠道设置
export function sources(data) {
    return post("/api/wenjuan/api/v1/rule/sourceChannel", data);
  }
//答题时长设置
export function theanSweris(data) {
  return post("/api/wenjuan/api/v1/rule/answerTime", data);
}
//无效问卷规则列表
export function ListInva(data) {
  return post("/api/wenjuan/api/v1/rule/invalidRuleList", data);
}
//无效规则禁用
export function forbid(data) {
  return post("/api/wenjuan/api/v1/rule/forbid", data);
}
//无效规则禁用
export function enable(data) {
  return post("/api/wenjuan/api/v1/rule/enable", data);
}