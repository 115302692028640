
import { createHashHistory } from 'history';
const history = createHashHistory();

const Main= {
    CHANGE_ACTIVE(state, { data }) {
        state.activeKey = data;
        sessionStorage.setItem('header_menu_key', data);
    },
    GET_BUTTON_ROLE(state,{data}){
        state.buttonRole = data;
    },
    GET_MENU_ROLE(state,{data}){
        let pathname = window.location.pathname;
        let o = data[0];
        function jump(url) {
            if (pathname === '/' && url) {
                history.push(url);
            }
        }
        if (o && o.children[0] && o.children[0].menuUrl) {
            let url = o.children[0].menuUrl;
            jump(url)
        } else {
            if (o && o.children[0] && o.children[0].children[0].menuUrl) {
                let url = o.children[0].children[0].menuUrl;
                jump(url);
            }
        }
        state.menuList = data;
    }
}
export default Main