import React, { Component } from 'react'
import { Button, Radio, Switch, Select, DatePicker, Space, Input, Checkbox, InputNumber } from 'antd'
import { restrictNum, timeastrict, participationConstraint, anonymousLimit, quality, answerNumber, getAllSetting } from "../../../api/heightSet";
import './index.less';
import moment from "moment";
const { Option } = Select;
const LeftIcon = () => {
    return <>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00642 4.01977H1.73841L5.61202 0.91489C5.66958 0.876625 5.71858 0.826822 5.75589 0.768635C5.7932 0.710448 5.81802 0.645146 5.82878 0.576865C5.83954 0.508585 5.836 0.438814 5.81838 0.371973C5.80077 0.305132 5.76947 0.242678 5.72645 0.188567C5.68344 0.134456 5.62966 0.0898674 5.56852 0.0576263C5.50738 0.0253852 5.4402 0.00619414 5.37126 0.00126885C5.30231 -0.00365644 5.23309 0.0057914 5.16798 0.0290139C5.10288 0.0522363 5.04331 0.0887272 4.99304 0.136175L0.00127564 4.12959V4.30929C-0.000425212 4.33255 -0.000425212 4.35591 0.00127564 4.37917V4.42909C-0.000425212 4.45236 -0.000425212 4.47571 0.00127564 4.49898V4.67868L4.99304 8.67209C5.04417 8.71339 5.10294 8.74421 5.16598 8.7628C5.22902 8.78139 5.29511 8.78737 5.36047 8.78042C5.42582 8.77347 5.48917 8.75371 5.54689 8.72228C5.60462 8.69084 5.65558 8.64835 5.69688 8.59721C5.73818 8.54608 5.769 8.48732 5.78759 8.42428C5.80617 8.36123 5.81216 8.29515 5.80521 8.22979C5.79826 8.16443 5.7785 8.10108 5.74706 8.04336C5.71563 7.98564 5.67313 7.93467 5.622 7.89338L1.73841 5.01812H9.00642C12.1912 5.01812 14.7869 7.25443 14.7869 10.0099C14.7869 12.7653 12.1912 15.0016 9.00642 15.0016H1.30912C1.17673 15.0016 1.04976 15.0542 0.956147 15.1479C0.862533 15.2415 0.809942 15.3684 0.809942 15.5008C0.809942 15.6332 0.862533 15.7602 0.956147 15.8538C1.04976 15.9474 1.17673 16 1.30912 16H9.00642C12.7902 16 15.7852 13.3144 15.7852 10.0099C15.7852 6.70534 12.7902 4.01977 9.00642 4.01977Z" fill="#333333" />
        </svg>
        <span style={{ marginLeft: "10px" }}>
            返回
        </span>
    </>
}
const Eye = () => {
    return <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99077 13.2933C4.81477 13.2933 2.21012 10.4606 1.23821 9.2427C0.888695 8.80247 0.888695 8.18947 1.23821 7.75033C2.21012 6.53261 4.81368 3.69995 7.99077 3.69995C11.1679 3.69995 13.7718 6.53261 14.7433 7.75033C15.0928 8.18947 15.0928 8.80247 14.7433 9.24214C13.7718 10.4606 11.1668 13.2933 7.99077 13.2933ZM7.99077 4.89433C5.25723 4.89433 2.84641 7.65065 2.17266 8.49586C2.84641 9.34256 5.2587 12.0989 7.99077 12.0989C10.7232 12.0989 13.1347 9.34256 13.8093 8.49751L13.8089 8.49586C13.1348 7.65067 10.7243 4.89433 7.99077 4.89433Z" fill="#3E78ED" />
        <path d="M7.99083 10.9042C6.67344 10.9042 5.60205 9.83263 5.60205 8.51524C5.60205 7.19785 6.67344 6.12646 7.99083 6.12646C8.26327 6.12646 8.52983 6.172 8.78538 6.26178C9.09673 6.3719 9.25938 6.71318 9.14888 7.024C9.03948 7.33482 8.6991 7.49581 8.38776 7.38857C8.26071 7.3436 8.12743 7.32085 7.99083 7.32085C7.33177 7.32085 6.79643 7.8567 6.79643 8.51523C6.79643 9.17376 7.33179 9.70963 7.99083 9.70963C8.64988 9.70963 9.18524 9.17376 9.18524 8.51523C9.18524 8.18515 9.45252 7.91803 9.78223 7.91803C10.1127 7.91803 10.38 8.18515 10.38 8.51523C10.38 9.83263 9.30823 10.9042 7.99083 10.9042Z" fill="#3E78ED" />
    </svg>
}

export default class heightSet extends Component {

    state = {
        RouterTab: [{
            name: "编辑内容",
            path: `/editQuestion/editContent?libid=${JSON.parse(sessionStorage.getItem("router")).result}&title=${JSON.parse(sessionStorage.getItem("router")).questionTitle}`
        }, {
            name: "高级设置",
            path: "/editQuestion/heightSet"
        }
        // , {
        //     name: "模板设置",
        //     path: `/editQuestion/moduleSet?libid=${JSON.parse(sessionStorage.getItem("router")).result}`,
        // }
        ],
        currentTanIndex: Number(sessionStorage.currentTanIndex) || 0,
        answer: {
            mobileTimes: false,
            ipTimes: false,
            able: '',
            num: '',
            ables: '',
            nums: '',
            time: false,
            startTime: '',
            endTime: '',
            globalTime: false,
            setgloDate: '',
            userScope: 0,
            anonymity: false,
            gather: 1,
            upLimit: false,
            ifCheckBegin: null,
            ifCheckEnd: null
        },
        optionArr: [],
        orgName: "",
    }
    go = (v, i) => {
        let urls = JSON.parse(sessionStorage.getItem("router"));
        if (v.name === '编辑内容') {
            this.props.history.push(`/editQuestion/editContent?libid=${urls.result}&title=${urls.questionTitle}&wjType=${urls.wjType}`);
        }
        // else if (v.name === '模板设置') {
        //     this.props.history.push(`/editQuestion/moduleSet?libid=${urls.result}`);
        // }
        else {
            this.props.history.push(v.path);
        }
        this.setState(() => {
            window.sessionStorage.currentTanIndex = i
            return {
                currentTanIndex: i
            }
        })
    }

    componentWillUnmount() {
        window.sessionStorage.currentTanIndex = null
    }
    componentDidMount() {

        let arr = []
        for (let i = 1; i <= 18; i++) {
            arr.push(i * 10)
        }
        this.setState({
            optionArr: arr,
        })
        this.getAllSetting()
    }
    //高级设置回显
    getAllSetting = () => {
        getAllSetting({
            libId: JSON.parse(sessionStorage.getItem("router")).result
        }).then(res => {
            if (!res.result) {
                return;
            }
            this.setState({
                setEcho: res.result,
                orgName: res.result.orgName
            })
        })
    }

    // 多选
    check = (e, name) => {
        let newAnswer = this.state.answer[name] = e;
        this.setState({
            newAnswer
        })
        let config = {
            ifEnable: this.state.setEcho && this.state.setEcho.isOpenBeginEndAttr === 1 ? 1 : 0,
            ifCheckBegin: this.state.answer.ifCheckBegin ? 1 : this.state.answer.ifCheckBegin === null ? this.state.setEcho && this.state.setEcho.beginEndAttr.isBeginSelected : 0,
            beginTime: this.state.answer.startTime || this.state.setEcho && this.state.setEcho.beginEndAttr.beginTime,
            ifCheckEnd: this.state.answer.ifCheckEnd ? 1 : this.state.answer.ifCheckEnd === null ? this.state.setEcho && this.state.setEcho.beginEndAttr.isEndSelected : 0,
            endTime: this.state.answer.endTime || this.state.setEcho && this.state.setEcho.beginEndAttr.endTime,
            libId: JSON.parse(sessionStorage.getItem("router")).result
        }
        timeastrict(config).then(res => {
            if (res.code === 0 && res.message === 'success') {
                return;
            } else {
                this.mesWarning(res.message)
            }
        })
    }
    // 开始结束时间
    time = (e, name) => {
        let newAnswer = this.state.answer[name] = moment(e).format("YYYY-MM-DD HH:mm:00"); // 需要转换时间
        this.setState({
            newAnswer
        })
        if (name === 'startTime' || name === 'endTime') {
            let config = {
                ifEnable: this.state.setEcho && this.state.setEcho.isOpenBeginEndAttr === 1 ? 1 : 0,
                ifCheckBegin: this.state.answer.ifCheckBegin ? 1 : this.state.answer.ifCheckBegin === null ? this.state.setEcho && this.state.setEcho.beginEndAttr.isBeginSelected : 0,
                beginTime: this.state.answer.startTime || this.state.setEcho && this.state.setEcho.beginEndAttr.beginTime,
                ifCheckEnd: this.state.answer.ifCheckEnd ? 1 : this.state.answer.ifCheckEnd === null ? this.state.setEcho && this.state.setEcho.beginEndAttr.isEndSelected : 0,
                endTime: this.state.answer.endTime || this.state.setEcho && this.state.setEcho.beginEndAttr.endTime,
                libId: JSON.parse(sessionStorage.getItem("router")).result
            }
            timeastrict(config).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    return;
                } else {
                    this.mesWarning(res.message)
                }
            })
            return;
        }
    }

    //下拉框
    select = (e, name) => {
        let newAnswer = this.state.answer[name] = e;
        this.setState({
            newAnswer
        });
        if (name === 'able' || name === 'num') {
            let config = {
                limitType: 1,
                ifEnable: this.state.setEcho && this.state.setEcho.isOpenIpAttr === 1 ? 1 : 0,
                limitUnit: this.state.answer.able || this.state.setEcho && this.state.setEcho?.ipAttr.ipLimitRule || '0',
                limitNum: this.state.answer.num || this.state.setEcho && this.state.setEcho?.ipAttr.ipLimit || '0',
                libId: JSON.parse(sessionStorage.getItem("router")).result

            }
            restrictNum(config).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    return;
                } else {
                    this.mesWarning(res.message)
                }
            })
            return;
        }
        if (name === 'ables' || name === 'nums') {
            let config = {
                limitType: 0,
                ifEnable: this.state.setEcho && this.state.setEcho.isOpenCookieAttr === 1 ? 1 : 0,
                limitUnit: this.state.answer.ables || this.state.setEcho && this.state.setEcho.cookieAttr?.limitUnit || '0',
                limitNum: this.state.answer.nums || this.state.setEcho && this.state.setEcho.cookieAttr?.cookieLimit || '0',
                libId: JSON.parse(sessionStorage.getItem("router")).result

            }
            restrictNum(config).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    return;
                } else {
                    this.mesWarning(res.message)
                }
            })
            return;
        }
        if (name === 'userScope') {
            participationConstraint({
                libId: JSON.parse(sessionStorage.getItem("router")).result,
                joinRange: this.state.answer.userScope
            }).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    return;
                } else {
                    this.mesWarning(res.message)
                }
            });
            return;

        }
        if (name === 'gather') {
            quality({
                libId: JSON.parse(sessionStorage.getItem("router")).result,
                ifEnable: this.state.setEcho && this.state.setEcho.isOpenLimitAttr === 1 ? 1 : 0,
                addNum: this.state.answer.gather,
            }).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    return;
                } else {
                    this.mesWarning(res.message)
                }
            });
            return;
        }
        if (name === 'setgloDate') {
            answerNumber({
                libId: JSON.parse(sessionStorage.getItem("router")).result,
                ifEnable: this.state.setEcho && this.state.setEcho.isOpenTimeAttr === 1 ? 1 : 0,
                timeLong: this.state.answer.setgloDate || this.state.setEcho && this.state.setEcho.timeAttr?.minuteLimit || '0'
            }).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    return;
                } else {
                    this.mesWarning(res.message)
                }
            });
            return;
        }
    }
    // 返回按钮
    handClick = () => {
        this.props.history.push('/main')
    }
    //开关
    answerSwitch = (e, name) => {
        // 判断name调用不同的·1接口
        let newAnswer = this.state.answer[name] = e;
        this.setState({
            newAnswer
        }, () => {
            if (name === 'mobileTimes') {
                let config = {
                    limitType: this.state.answer.mobileTimes ? '0' : '0',
                    ifEnable: this.state.answer.mobileTimes ? '1' : '0',
                    limitUnit: this.state.answer.ables || this.state.setEcho && this.state.setEcho.cookieAttr?.limitUnit || '0',
                    limitNum: this.state.answer.nums || this.state.setEcho && this.state.setEcho.cookieAttr?.cookieLimit || '0',
                    libId: JSON.parse(sessionStorage.getItem("router")).result

                }
                restrictNum(config).then(res => {
                    if (res.code === 0 && res.message === 'success') {
                        // this.mesSuccess('已开启')
                        this.getAllSetting()
                    } else {
                        this.mesWarning(res.message)
                    }
                })
                return
            }
            if (name === 'ipTimes') {
                let config = {
                    limitType: this.state.answer.ipTimes ? '1' : '1',
                    ifEnable: this.state.answer.ipTimes ? '1' : '0',
                    limitUnit: this.state.answer.able || this.state.setEcho && this.state.setEcho.ipAttr?.ipLimitRule || '0',
                    limitNum: this.state.answer.num || this.state.setEcho && this.state.setEcho.ipAttr?.ipLimit || '0',
                    libId: JSON.parse(sessionStorage.getItem("router")).result

                }
                restrictNum(config).then(res => {
                    if (res.code === 0 && res.message === 'success') {
                        // this.mesSuccess('已开启')
                        this.getAllSetting()
                    } else {
                        this.mesWarning(res.message);
                    }
                })
                return
            }
        })
        if (name === 'time') {
            let config = {
                ifEnable: this.state.answer.time ? '1' : '0',
                ifCheckBegin: this.state.answer.ifCheckBegin ? 1 : 0,
                beginTime: this.state.answer.startTime,
                ifCheckEnd: this.state.answer.ifCheckEnd ? 1 : 0,
                endTime: this.state.answer.endTime,
                libId: JSON.parse(sessionStorage.getItem("router")).result
            }
            timeastrict(config).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    // this.mesSuccess('已开启')
                    this.getAllSetting()
                } else {
                    this.mesWarning(res.message)
                }
            })
            return
        }
        if (name === 'anonymity') {
            anonymousLimit({
                libId: JSON.parse(sessionStorage.getItem("router")).result,
                ifAnony: this.state.answer.anonymity ? 1 : 0
            }).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    // this.mesSuccess('已开启')
                    this.getAllSetting()
                } else {
                    this.mesWarning(res.message)
                }
            });
            return;
        }
        if (name === 'upLimit') {
            quality({
                libId: JSON.parse(sessionStorage.getItem("router")).result,
                ifEnable: this.state.answer.upLimit ? 1 : 0,
                addNum: this.state.answer.gather,
            }).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    // this.mesSuccess('已开启')
                } else {
                    this.mesWarning(res.message)
                }
            });
            return;
        }
        if (name === 'ontime') {
            answerNumber({
                libId: JSON.parse(sessionStorage.getItem("router")).result,
                ifEnable: this.state.answer.ontime ? 1 : 0,
                timeLong: this.state.answer.setgloDate || this.state.setEcho && this.state.setEcho.timeAttr?.minuteLimit || '0'
            }).then(res => {
                if (res.code === 0 && res.message === 'success') {
                    // this.mesSuccess('已开启')
                    this.getAllSetting()
                } else {
                    this.mesWarning(res.message)
                }
            });
            return;
        }
    }
    render() {
        const { CenterText } = this.props
        let { RouterTab, currentTanIndex, setEcho } = this.state;
        return (
            <div className="advancedSettings">
                <div className='headerAction'>
                    <div className="headerActionleft" onClick={() => this.handClick()}>
                        <LeftIcon />
                    </div>
                    <div className="headerActionright">
                        <div className="uul">
                            {RouterTab.map((v, i) => {
                                return <div key={v.path} className={`item ${1 === i ? 'active' : ''}`} onClick={() => this.go(v, i)}>
                                    {v.name}
                                </div>
                            })}
                        </div>
                        <div className="rrl">
                            <div className="item" onClick={this.props._prop_preview}>
                                {/* <span className="item_sp" >预览</span> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="senior_main">
                    <div className="theanswerlimit">
                        <div className="theanswerlimit_box">
                            <div className="limit">
                                <i style={{ padding: '0 10px' }}>
                                    <svg width="4" height="19" viewBox="0 0 4 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="4" height="19" rx="2" fill="url(#paint0_linear)" />
                                        <defs>
                                            <linearGradient id="paint0_linear" x1="0.285714" y1="-4.97496e-07" x2="10.7536" y2="3.65113" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#6EA8FF" />
                                                <stop offset="1" stopColor="#485AFE" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </i>
                                答题限制
                            </div>
                            <div className="answer_num">
                                <h3>答题次数</h3>
                                <div className="answer_border">
                                    <div className="iphone">
                                        <p>每台电脑或手机答题次数限制</p>
                                        <p>根据答题者浏览器cookie判断，如用户清空cookie或更换浏览器则限制失效</p>
                                    </div>
                                    <div
                                        style={{ float: "right", }}
                                    >
                                        <span>
                                            <Switch
                                                // checked={setEcho && setEcho.isOpenCookieAttr}
                                                onChange={(e) => this.answerSwitch(e, 'mobileTimes')}
                                                checkedChildren="开"
                                                unCheckedChildren="关"
                                                key={setEcho && setEcho.isOpenCookieAttr === 1 ? true : false}
                                                defaultChecked={setEcho && setEcho.isOpenCookieAttr === 1 ? true : false}
                                            />
                                        </span>
                                    </div>
                                </div>

                                {setEcho && setEcho.isOpenCookieAttr === 1 || this.state.answer.mobileTimes ? <div className="answer_acar">
                                    <Select defaultValue={setEcho && setEcho.cookieAttr?.limitUnit || 0} className="answer_car" onChange={(e) => this.select(e, 'ables')} >
                                        <Option value={0}>只能</Option>
                                        <Option value={1}>每小时</Option>
                                        <Option value={2}>每天</Option>
                                    </Select>
                                    <span className="acar">答题</span>
                                    <Select defaultValue={setEcho && setEcho.cookieAttr?.cookieLimit || 0} className="answer_car" onChange={(e) => this.select(e, 'nums')}>
                                        <Option value={1}>1次</Option>
                                        <Option value={2}>2次</Option>
                                        <Option value={3}>3次</Option>
                                        <Option value={4}>4次</Option>
                                        <Option value={5}>5次</Option>
                                        <Option value={6}>6次</Option>
                                        <Option value={7}>7次</Option>
                                        <Option value={8}>8次</Option>
                                        <Option value={9}>9次</Option>
                                        <Option value={10}>10次</Option>
                                    </Select>
                                </div> : null}
                                <div className="answer_ip">
                                    <div className="ip_limit">
                                        <p>每个IP答题次数限制</p>
                                        <p>根据用户IP地址判断，同一网络环境下IP地址相同，院校、企业内部调研慎选</p>
                                    </div>
                                    <div style={{ float: "right", }}>
                                        <span>
                                            <Switch
                                                // checked={this.state.answer.ipTimes}
                                                key={setEcho && setEcho.isOpenIpAttr === 1 ? true : false}
                                                defaultChecked={setEcho && setEcho.isOpenIpAttr === 1 ? true : false}
                                                onChange={(e) => this.answerSwitch(e, 'ipTimes')}
                                                checkedChildren="开"
                                                unCheckedChildren="关"
                                            />
                                        </span>
                                    </div>
                                </div>
                                {setEcho && setEcho.isOpenIpAttr === 1 || this.state.answer.ipTimes ? <div className="answer_acar">
                                    <Select defaultValue={setEcho && setEcho.ipAttr.ipLimitRule || 0} className="answer_car" onChange={(e) => this.select(e, 'able')} >
                                        <Option value={0}>只能</Option>
                                        <Option value={1}>每小时</Option>
                                        <Option value={2}>每天</Option>
                                    </Select>
                                    <span className="acar">答题</span>
                                    <Select defaultValue={setEcho && setEcho.ipAttr.ipLimit || 0} className="answer_car" onChange={(e) => this.select(e, 'num')}>
                                        <Option value={1}>1次</Option>
                                        <Option value={2}>2次</Option>
                                        <Option value={3}>3次</Option>
                                        <Option value={4}>4次</Option>
                                        <Option value={5}>5次</Option>
                                        <Option value={6}>6次</Option>
                                        <Option value={7}>7次</Option>
                                        <Option value={8}>8次</Option>
                                        <Option value={9}>9次</Option>
                                        <Option value={10}>10次</Option>
                                    </Select>
                                </div> : null}
                            </div>
                            <div className="answerlimit">
                                <h3>答题限制</h3>
                                <div className="answerlimit_time">
                                    <div className="answerlimit_time1">
                                        <p>设置开始/结束时间</p>
                                        {setEcho && setEcho.isOpenBeginEndAttr === 1 || this.state.answer.time ? <div className="answerlimit_open_right">
                                            <Checkbox
                                                defaultChecked={setEcho && setEcho.beginEndAttr.isBeginSelected === 1 ? true : false}
                                                // checked={this.state.answer.ifCheckBegin}
                                                style={{ paddingRight: 20, paddingLeft: 10 }}
                                                onChange={(e) => this.check(e.target.checked, 'ifCheckBegin')}
                                            />
                                            开始时间
                                            <DatePicker style={{ marginLeft: "20px" }}
                                                format="YYYY-MM-DD HH:mm:00"
                                                defaultValue={setEcho && setEcho.beginEndAttr.beginTime ? moment(setEcho && setEcho.beginEndAttr.beginTime, 'YYYY-MM-DD HH:mm:00') : null}
                                                onChange={(e) => this.time(e, 'startTime')}
                                            />
                                            <p className="endTime" style={{ margin: "20px 0" }}>
                                                <Checkbox
                                                    // checked={this.state.answer.ifCheckEnd}
                                                    defaultChecked={setEcho && setEcho.beginEndAttr.isEndSelected === 1 ? true : false}
                                                    style={{ paddingRight: 20, paddingLeft: 10 }}
                                                    onChange={(e) => this.check(e.target.checked, 'ifCheckEnd')}
                                                />
                                                结束时间
                                                <DatePicker style={{ marginLeft: 21 }}
                                                    defaultValue={setEcho && setEcho.beginEndAttr.endTime ? moment(setEcho && setEcho.beginEndAttr.endTime, 'YYYY-MM-DD HH:mm:00') : null}
                                                    format="YYYY-MM-DD HH:mm:00"
                                                    onChange={(e) => this.time(e, 'endTime')}
                                                />
                                            </p>

                                        </div> : null}
                                    </div>
                                    <div className="answerlimit_open">
                                        <span>
                                            <Switch
                                                onChange={(e) => this.answerSwitch(e, 'time')}
                                                checkedChildren="开"
                                                unCheckedChildren="关"
                                                key={setEcho && setEcho.isOpenBeginEndAttr === 1 ? true : false}
                                                defaultChecked={setEcho && setEcho.isOpenBeginEndAttr === 1 ? true : false}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="globaltime" style={{ paddingBottom: 20, }}>
                                <p style={{ position: "relative", paddingLeft: 37, }}>设置全局答题时间
                                    <span style={{ position: "absolute", right: 19, top: 0, }}>
                                        <Switch
                                            key={setEcho && setEcho.isOpenTimeAttr === 1 ? true : false}
                                            defaultChecked={setEcho && setEcho.isOpenTimeAttr === 1 ? true : false}
                                            onChange={(e) => this.answerSwitch(e, 'ontime')}
                                            checkedChildren="开"
                                            unCheckedChildren="关"
                                        />
                                    </span>
                                </p>
                                {setEcho && setEcho.isOpenTimeAttr === 1 || this.state.answer.ontime ? <div style={{ width: "100%", marginLeft: 20, marginTop: 33, border: "1px solid  #FAFAFA", background: "#F6F7FB" }} className="answer">

                                    <p style={{ paddingTop: 10, paddingLeft: 17 }}>设置时间
                                        <Select
                                            defaultValue={setEcho && setEcho.timeAttr?.minuteLimit || 0}
                                            style={{ width: 130, paddingLeft: "20px" }}
                                            onChange={(e) => this.select(e, 'setgloDate')}
                                        >
                                            {
                                                this.state.optionArr.map((item, key) => {
                                                    return <Option key={key + 1} value={item}>{item}分钟</Option>
                                                })
                                            }
                                        </Select>
                                    </p>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="weChatlimit">
                    <div className="limit_main">
                        <div className="limit_box">
                            <i>
                                <svg width="4" height="19" viewBox="0 0 4 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="4" height="19" rx="2" fill="url(#paint0_linear)" />
                                    <defs>
                                        <linearGradient id="paint0_linear" x1="0.285714" y1="-4.97496e-07" x2="10.7536" y2="3.65113" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#6EA8FF" />
                                            <stop offset="1" stopColor="#485AFE" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </i>
                            <span>参与范围限制</span>
                        </div>
                        {setEcho && setEcho.isAnony === 1 || this.state.answer.anonymity ? <div>
                            <p style={{ margin: "20px 20px" }}>调研是否允许公开全网，还是必须平台注册用户参与</p>
                            <div className="limit_ipt">
                                <Radio.Group
                                    onChange={(e) => this.select(e.target.value, 'userScope')}
                                    // value={this.state.answer.userScope}
                                    defaultValue={setEcho && setEcho.joinRange}

                                >
                                    <Space direction="vertical">
                                        <Radio value={0}><span style={{ margin: "20px 10px" }}>全网公开答题</span><span style={{ marginLeft: "10px", color: "#888888" }}>分享链接后任何用户均可参与</span></Radio>
                                        <Radio value={1}><span style={{ margin: "20px 10px" }}>限定平台注册用户答题</span><span style={{ marginLeft: "10px", color: "#888888" }}>只有在全平台注册的账号才能参与</span></Radio>
                                        <Radio value={2}><span style={{ margin: "20px 10px" }}>限定本机构内用户答题</span><span style={{ marginLeft: "10px", color: "#888888" }}>开启后，只有平台注册用户且属于本机构账号的用户才能参与</span></Radio>
                                    </Space>
                                </Radio.Group>
                                <div style={{ marginLeft: "193px", marginTop: 7, color: "#888888" }}>当前机构：<a style={{ borderBottom: "1px solid" }}>{this.state.orgName}</a></div>
                            </div>
                        </div> : null}


                        <div className="anonymous">
                            <div style={{ float: "left" }}>
                                <p>允许匿名参与</p>
                                <p>不开启时，将实名采集用户信息，用户必须输入姓名才能提交问卷</p>
                            </div>
                            <div style={{ float: "right" }}>
                                <span>
                                    <Switch
                                        key={setEcho && setEcho.isAnony === 1 ? true : false}
                                        defaultChecked={setEcho && setEcho.isAnony === 1 ? true : false}
                                        onChange={(e) => this.answerSwitch(e, 'anonymity')}
                                        checkedChildren="开"
                                        unCheckedChildren="关"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="remindset">
                    <div className="aaa"> 提醒设置</div>
                </div>
                <div className="remindset">
                    <div className="aaa"> 微信设置</div>
                </div> */}
                <div className="thequalitycontrol">
                    <div className="thequalitycontrol_box">
                        <div className="thequalitycontrol_font">
                            <i>
                                <svg width="4" height="19" viewBox="0 0 4 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="4" height="19" rx="2" fill="url(#paint0_linear)" />
                                    <defs>
                                        <linearGradient id="paint0_linear" x1="0.285714" y1="-4.97496e-07" x2="10.7536" y2="3.65113" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#6EA8FF" />
                                            <stop offset="1" stopColor="#485AFE" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </i>
                            <span>质量控制</span>
                        </div>
                        <p className="quality">答题质量</p>
                        <div className="quality_ceiling">
                            <p>
                                <span>设置答题数据上限</span>
                                <span className="quality_pro">
                                    <Switch
                                        // checked={this.state.answer.upLimit}
                                        key={setEcho && setEcho.isOpenLimitAttr === 1 ? true : false}
                                        defaultChecked={setEcho && setEcho.isOpenLimitAttr === 1 ? true : false}

                                        onChange={(e) => this.answerSwitch(e, 'upLimit')}
                                        checkedChildren="开"
                                        unCheckedChildren="关"
                                    />
                                </span>
                            </p>
                        </div>
                        {setEcho && setEcho.isOpenLimitAttr === 1 || this.state.answer.upLimit ? <p className="quality_p">
                            <span style={{ paddingRight: 15 }}>收集</span>
                            <Input style={{ width: "100px" }} onChange={(e) => {
                                if (e.target.value <= 5000) {
                                    this.select(e.target.value, "gather")
                                } else {
                                    this.mesWarning('请输入有效数字，最大5000份')
                                }
                            }} defaultValue={setEcho && setEcho.qualityAttr.numLimit} />
                            <span style={{ paddingLeft: 15 }}>份数据时结束</span>

                        </p> : null}
                        <div className="screening">
                            <span>筛选无效答卷</span>
                            <i onClick={() => {
                                this.props.history.push("/invalidanswer")
                            }}>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0)">
                                        <path d="M2.56239 8.7794C2.56243 8.84158 2.57509 8.9031 2.59958 8.96025C2.62407 9.0174 2.65989 9.06899 2.70489 9.1119C2.79787 9.20055 2.92141 9.25 3.04989 9.25C3.17836 9.25 3.3019 9.20055 3.39488 9.1119L7.31988 5.3319C7.36512 5.28914 7.40116 5.2376 7.4258 5.18043C7.45043 5.12326 7.46313 5.06166 7.46313 4.9994C7.46313 4.93715 7.45043 4.87555 7.4258 4.81838C7.40116 4.76121 7.36512 4.70966 7.31988 4.6669C7.22689 4.57826 7.10335 4.52881 6.97488 4.52881C6.84641 4.52881 6.72287 4.57826 6.62988 4.6669L2.70489 8.4469C2.65989 8.48982 2.62407 8.54141 2.59958 8.59856C2.57509 8.65571 2.56243 8.71723 2.56239 8.7794Z" fill="#4C4C4C" />
                                        <path d="M2.56239 1.2201C2.56243 1.28228 2.57509 1.3438 2.59958 1.40095C2.62407 1.4581 2.65989 1.50969 2.70489 1.5526L6.62988 5.33261C6.72287 5.42125 6.84641 5.4707 6.97488 5.4707C7.10335 5.4707 7.22689 5.42125 7.31988 5.33261C7.36513 5.28985 7.40117 5.2383 7.4258 5.18113C7.45043 5.12396 7.46314 5.06236 7.46314 5.00011C7.46314 4.93785 7.45043 4.87626 7.4258 4.81908C7.40117 4.76191 7.36513 4.71037 7.31988 4.66761L3.39488 0.887602C3.3019 0.798957 3.17836 0.749506 3.04989 0.749506C2.92141 0.749506 2.79787 0.798957 2.70489 0.887602C2.65989 0.930517 2.62407 0.982107 2.59958 1.03926C2.57509 1.09641 2.56243 1.15793 2.56239 1.2201Z" fill="#4C4C4C" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </i>
                        </div>
                    </div>
                </div>
                {/* <div className="otherSettings">
                    其他设置
                </div> */}
            </div>
        )
    }
}

