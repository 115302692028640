import { post } from '../../until/axios_instans';

//获取按钮权限
export function getButtonRole(data){
    return post('/api/ucenter/api/v1/permission/buttons',data);
}

//获取菜单权限
export function getMenu(data){
    return post('/api/ucenter/api/v1/permission/routers',data);
}