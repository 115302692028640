import React, { Component } from 'react';
import { ConfigProvider,message } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import  Dayjs  from 'dayjs';
import 'dayjs/locale/zh-cn'
Dayjs.locale('zh-cn')

message.config({
    top: 200,
    duration: 3,
    maxCount: 1,
    
  });
 const Compon=(Com) => {
    return class extends Component {
        render() {
            return <ConfigProvider locale={zhCN}>
                <Com />            
            </ConfigProvider>
        }
    }
}
export default Compon