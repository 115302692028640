import React, { useState, useEffect } from 'react';


const MyImage = ({ className, id, type, defaultSrc = '123', imgOption, onClick, suffix }) => {

    const { $$uploadRc } = window;
    let [index, setIndex] = useState(0);
    let [imgList, setList] = useState([]);
    let s = (suffix && suffix) || '.jpg';
    useEffect(() => {
        let arr = type ? [`${id}_${type}${s}`, id] : [id, `${id}_m${s}`];
        setList([...arr, defaultSrc]);
    }, [id]);
    const errorFn = e => {
        if (index < imgList.length - 1) {
            index++;
            setIndex(index)
            e.target.src = $$uploadRc(imgList[index]);
        }
    }
    const imgProps = {
        ...imgOption,
        className,
        onClick,
        src: $$uploadRc(imgList[index]),
        onError: errorFn
    }
    return imgList[index] ? <img {...imgProps} /> : null
}

export default MyImage;