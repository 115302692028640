import { post } from "../../until/axios_instans";

// 模板回显数据
export function templateConfig(data) {
    return post('api/wenjuan/api/v1/wenjuan/templateConfig', data);
}

//模板发布
export function templateRelease(data) {
    return post('api/wenjuan/api/v1/wenjuan/templateRelease', data);
}
